<template>
  <el-upload
    ref="uploadRef"
    :file-list="fileList"
    :action="uploadData.host"
    :accept="accept"
    :limit="limit"
    :list-type="listType"
    :http-request="httpRequest"
    :before-upload="beforeUpload"
    :on-success="onSuccess"
    :disabled="disabled"
    :class="{ 'disabled-upload': disabledUpload }"
  >
    <div class="flex flex-col items-center">
      <img src="@components/assets/icon-upload.png" alt="" class="w-14 h-14" />
      <div class="leading-none">
        <span class="leading-none text-xs">将图片或者视频拖放到这里</span>
        <p class="leading-none text-xs text-primary">或，<span class="underline underline-offset-4">点击选择图片或视频</span></p>
      </div>
    </div>

    <template #file="{ file }: { file: UploadFile }">
      <div class="w-full h-full">
        <img v-if="['png', 'jpg', 'jpeg', 'gif'].includes(getFileExt(file))" class="el-upload-list__item-thumbnail" :src="file.url" alt="" />

        <div v-else class="w-full h-full flex items-center justify-center">
          <el-icon :size="48"><Document /></el-icon>
        </div>

        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="onPreview(file)">
            <el-icon><zoom-in /></el-icon>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="onDownload(file)">
            <el-icon><Download /></el-icon>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="onRemove(file)">
            <el-icon><Delete /></el-icon>
          </span>
        </span>
      </div>
    </template>
  </el-upload>

  <el-dialog v-model="dialogVisible">
    <img :src="dialogImageUrl" class="w-full" />
  </el-dialog>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'
import { ZoomIn, Download, Delete, Document } from '@element-plus/icons-vue'
import { useStore } from '@enocloud/utils'

import type { UploadProps, UploadUserFile, UploadFile, UploadRawFile, UploadRequestHandler } from 'element-plus'
import { isArray, get } from 'lodash-es'

interface Props {
  accept?: string
  limit?: number
  listType?: 'text' | 'picture' | 'picture-card'
  modelValue?: string | Record<string, any> | string[] | Record<string, any>[]
  multiple?: boolean
  disabled?: boolean
  urlKey?: string
  dir?: string
  watermark?: boolean
}

interface Emits {
  (e: 'update:model-value', value: string | Record<string, any> | string[] | Record<string, any>[]): void
  (e: 'change', value: string | Record<string, any> | string[] | Record<string, any>[]): void
}

const props = withDefaults(defineProps<Props>(), { accept: 'image/*,video/*', listType: 'picture-card' })
const emits = defineEmits<Emits>()

const dialogVisible = ref(false)
const dialogImageUrl = ref('')

const store = useStore()

const uploadRef = ref(null)

const key = ref('')

const uploadData = computed(() => {
  return {
    ...store.ossInfo,
    OSSAccessKeyId: store.ossInfo?.accessId,
    Signature: store.ossInfo?.signature,
    key: key.value
  }
})

const beforeUpload = (urf: UploadRawFile): Promise<undefined> => {
  key.value = props.dir ? `${uploadData.value.dir}/${props.dir}/${urf.uid}${urf.name}` : `${uploadData.value.dir}/${urf.uid}${urf.name}`
  return new Promise((resolve) => setTimeout(resolve, 0))
}

const disabledUpload = ref(false)
const uidMap = new Map()

const fileList = computed<UploadUserFile[]>(() => {
  return props.modelValue
    ? props.multiple && isArray(props.modelValue)
      ? props.modelValue.map((item) => {
          return {
            name: '',
            url: get(item, props.urlKey ?? 'url', item) as string,
            uid: uidMap.get(get(item, props.urlKey ?? 'url', item) as string)
          }
        })
      : [
          {
            name: '',
            url: get(props.modelValue, props.urlKey ?? 'url', props.modelValue) as string,
            uid: uidMap.get(get(props.modelValue, props.urlKey ?? 'url', props.modelValue) as string)
          }
        ]
    : []
})

const httpRequest: UploadRequestHandler = async ({ file, action, onSuccess, onError }) => {
  const formData = new FormData()
  Object.entries(uploadData.value).forEach(([key, value]) => formData.append(key, `${value}`))
  formData.append('file', file)
  return window.fetch(action, { method: 'POST', body: formData }).then((r) => r.body)
}

const onSuccess: UploadProps['onSuccess'] = (res, uf, ufs) => {
  const url = `${uploadData.value.host}/${uploadData.value.key}`
  uidMap.set(url, uf.uid)
  let value: any
  if (props.multiple) {
    value = isArray(props.modelValue) ? props.modelValue : []
    value.push(props.urlKey ? { [props.urlKey]: url } : url)
  } else {
    value = props.urlKey ? { [props.urlKey]: url } : url
  }
  changeUploadDisabled()
  emits('update:model-value', value)
  emits('change', value)
}

const getFileExt = (uf: UploadFile) => {
  const index = uf.url?.lastIndexOf('.')!
  return uf.url?.substring(index + 1) ?? ''
}

const onPreview = (uf: UploadFile) => {
  if (['png', 'jpg', 'jpeg', 'gif'].includes(getFileExt(uf))) {
    dialogImageUrl.value = uf.url!
    dialogVisible.value = true
  } else {
    window.open(uf.url)
  }
}

const onDownload = (uf: UploadFile) => {
  window.open(uf.url)
}

const onRemove = (uf: UploadFile) => {
  let value: any = null
  if (props.multiple) {
    value = isArray(props.modelValue) ? props.modelValue : []
    const index = value.findIndex((v: any) => get(v, props.urlKey ?? 'url', v) === uf.url)
    if (index > -1) value.splice(index, 1)
  }
  changeUploadDisabled()
  emits('update:model-value', value)
  emits('change', value)
}

const changeUploadDisabled = () => {
  disabledUpload.value = Boolean(props.limit && fileList.value.length >= props.limit)
}

watchEffect(
  () => {
    for (const file of fileList.value) {
      if (!uidMap.get(file.url)) {
        uidMap.set(file.url, file.uid)
      }
    }
  },
  { flush: 'post' }
)
</script>

<style>
.el-upload--picture-card {
  --el-upload-picture-card-size: 200px;
  height: 150px;
  background-color: #fff;
}

.el-upload-list--picture-card {
  --el-upload-list-picture-card-size: 200px;
}

.el-upload-list--picture-card .el-upload-list__item {
  height: 150px;
}

.disabled-upload .el-upload--picture-card {
  display: none;
}
</style>
