<template>
  <div class="h-full bg-neutral-100 flex flex-col">
    <view-item-header></view-item-header>

    <div class="px-6 flex-1 overflow-auto">
      <div class="h-full flex flex-col">
        <div class="h-20 flex items-center justify-between">
          <span class="font-medium text-2xl">网点设置</span>

          <div>
            <slot name="head"></slot>
          </div>

          <div class="inline-flex items-center gap-6"></div>
        </div>

        <en-card body-class="h-full flex gap-6" class="flex-1 overflow-auto">
          <div class="w-70 flex flex-col">
            <span class="text-primary">当前: {{ store.user.branch?.name }}</span>

            <div class="mt-6 bg-primary rounded px-4 py-2 flex items-center gap-2 text-white">
              <i-school class="w-4 h-4"></i-school>
              <span>{{ routerActive?.message }}</span>
            </div>

            <div class="flex-1 overflow-auto">
              <el-scrollbar>
                <div class="flex flex-col gap-4 px-10 py-4">
                  <span
                    v-for="menu of routerActive?.children"
                    :class="['hover:text-primary', 'hover:cursor-pointer', { 'text-primary': $route.fullPath === menu.path }]"
                    @click="$router.push(menu.path!)"
                  >
                    {{ menu.message }}
                  </span>
                </div>
              </el-scrollbar>
            </div>
          </div>

          <div class="h-full w-px bg-gray-100"></div>

          <div class="flex-1 flex flex-col gap-6 overflow-auto">
            <div class="flex items-center justify-between">
              <div class="flex flex-col gap-2 text-gray-400">
                <span>
                  {{ routerActive?.message }} /
                  <span class="text-black">
                    {{ routerMenu?.message }}
                  </span>
                </span>
                <span class="text-sm">有问题请沟通我们 400-996-0171 或 邮件我们。</span>
              </div>

              <el-space :size="24">
                <slot name="operation"></slot>
              </el-space>
            </div>

            <div class="flex-1 overflow-auto">
              <div class="h-full">
                <slot></slot>
              </div>
            </div>
          </div>
        </en-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { find, includes, map } from 'lodash-es'
import { School as ISchool } from '@element-plus/icons-vue'
import ViewItemHeader from '@components/project/view-item-header.vue'

import EnCard from '@components/element/en-card.vue'

import { settingMenus } from '@enocloud/hooks'
import { useStore } from '@enocloud/utils'

interface Props {
  id?: 'SETTING_TENANT' | 'SETTING_BUSINESS' | 'SETTING_ENOCH'
}

const props = defineProps<Props>()
const store = useStore()
const route = useRoute()
const router = useRouter()

const routerRoot = computed(() => find(settingMenus, (item) => includes(map(item.children, 'path'), route.fullPath)))
const routerActive = computed(() => find(settingMenus, ['id', routerRoot.value?.id]))
const routerMenu = computed(() => find(routerActive.value?.children, ['path', route.fullPath]))
</script>
