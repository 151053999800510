<template>
  <div class="flex flex-wrap gap-6">
    <slot name="fileList" :file-list="_fileList"></slot>

    <div @click="onUploadClick">
      <slot></slot>
    </div>

    <input ref="inputRef" type="file" class="hidden" :accept="accept" @change="onInputFileChange" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

export interface UploadFile {
  action: string
  file: File
  url: string
  uid: number
  percentage: number
  status: 'ready' | 'uploading' | 'success' | 'error'
  data?: Record<string, any>
}

export interface UploadContext {
  removeUploadFileByUid: (uid: number) => void
}

interface Props {
  action?: string
  accept?: string
  beforeUpload?: (uploadFile: UploadFile) => Promise<boolean>
  disabled?: boolean
  fileList?: string[]
  multiple?: boolean
  onSuccess?: (uploadFile: UploadFile, response: XMLHttpRequest['response']) => void
  onError?: (uploadFile: UploadFile) => void
}

const props = defineProps<Props>()

const inputRef = ref<HTMLInputElement | null>()

const uploadFiles = ref<UploadFile[]>([])

const _fileList = computed(() => {
  return props.fileList
    ?.map((item, index) => {
      return {
        url: item,
        uid: index,
        percentage: 100,
        status: 'success'
      }
    })
    .concat(uploadFiles.value) as UploadFile[]
})

interface UploadRequestOptions {
  action: string
  file: File
  data?: Record<string, any>
  onError: (evt: ProgressEvent, xhr: XMLHttpRequest) => void
  onProgress: (evt: ProgressEvent, xhr: XMLHttpRequest) => void
  onSuccess: (evt: ProgressEvent, xhr: XMLHttpRequest) => void
}

const uploadRequest = (options: UploadRequestOptions) => {
  const { action, data, file } = options

  const xhr = new XMLHttpRequest()
  xhr.upload.onerror = function (evt) {
    options.onError(evt, xhr)
  }
  xhr.upload.onprogress = function (evt) {
    options.onProgress(evt, xhr)
  }
  xhr.onload = function (evt) {
    if (this.status < 200 || this.status >= 300) {
      options.onError(evt, xhr)
      return
    }
    options.onSuccess(evt, xhr)
  }

  const formData = new FormData()
  for (const [key, value] of Object.entries(data ?? {})) formData.append(key, value)
  formData.append('file', file, file.name)

  xhr.open('POST', action, true)
  xhr.send(formData)
  return xhr
}

const handler = {
  async before(file: File) {
    const uploadFile: UploadFile = {
      action: props.action || '',
      file,
      percentage: 0,
      status: 'uploading',
      uid: new Date().getTime(),
      url: '',
      data: {}
    }

    if (props.beforeUpload) {
      try {
        const valid = await props.beforeUpload(uploadFile)
        if (!valid) return
      } catch (err) {
        return
      }
    }

    handler.start(uploadFile)
  },
  start(uploadFile: UploadFile) {
    uploadFiles.value.push(uploadFile)
    handler.uploading(uploadFile)
  },
  uploading(uploadFile: UploadFile) {
    const options: UploadRequestOptions = {
      action: uploadFile.action,
      data: uploadFile.data,
      file: uploadFile.file,
      onProgress(event, xhr) {
        uploadFile.percentage = event.total > 0 ? (event.loaded / event.total) * 100 : 0
      },
      onSuccess(event, xhr) {
        uploadFile.status = 'success'
        handler.success(uploadFile, xhr)
      },
      onError(event, xhr) {
        uploadFile.status = 'error'
        handler.error(uploadFile, xhr)
      }
    }
    uploadRequest(options)
  },
  success(uploadFile: UploadFile, xhr: XMLHttpRequest) {
    const index = uploadFiles.value.findIndex((item) => item.uid === uploadFile.uid)
    if (index > -1) uploadFiles.value.splice(index, 1)
    props.onSuccess?.(uploadFile, xhr.response ? JSON.parse(xhr.response) : '')
  },
  error(uploadFile: UploadFile, xhr: XMLHttpRequest) {
    props.onError?.(uploadFile)
  }
}

const onUploadClick = () => {
  if (props.disabled) return
  inputRef.value?.click()
}

const onInputFileChange = (e: Event) => {
  const files = (e.target as HTMLInputElement).files
  if (!files) return
  Array.from(files).map(handler.before)
  inputRef.value!.value = ''
}
</script>
