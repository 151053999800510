<template>
  <el-tab-pane :label="label" :name="name" :disabled="disabled">
    <template #label>
      <div class="relative">
        <span>{{ label }}</span>
        <span
          v-if="badge"
          ref="badgeRef"
          :class="`absolute top-0 -right-2 text-xs text-primary ${colorClass}`"
          :style="{ right: `-${badgeRect?.width}px` }"
        >
          {{ badge }}
        </span>
      </div>
    </template>
    <slot></slot>
  </el-tab-pane>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

interface Props {
  badge?: string | number
  closable?: boolean
  disabled?: boolean
  label?: string
  lazy?: boolean
  name?: string | number
  colorClass?: string
}

defineProps<Props>()

const badgeRef = ref<HTMLSpanElement | null>()

const badgeRect = computed(() => badgeRef.value?.getBoundingClientRect?.())
</script>
