<template>
  <el-autocomplete
    v-model="_modelValue"
    :fetch-suggestions="fetchSuggestion"
    :placeholder="placeholder"
    :debounce="debounce"
    :highlightFirstItem="highlightFirstItem"
    :valueKey="valueKey"
    @select="onSelect"
  ></el-autocomplete>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  debounce?: number
  fetchSuggestion?: (queryString: string, cb: (arg: any) => void) => void
  highlightFirstItem?: boolean
  modelValue?: string
  placeholder?: string
  valueKey?: string
}

interface Emits {
  (e: 'update:model-value', value?: string): void
  (e: 'change', value?: string): void
  (e: 'select', value?: Record<string, any>): void
}

const props = withDefaults(defineProps<Props>(), { debounce: 300, placeholder: '请输入' })
const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {

    emits('update:model-value', value)
    emits('change', value)
  }
})

const onSelect = (value: Record<string, any>) => emits('select', value)
</script>
