<template>
  <el-radio v-model="_modelValue" :disabled="disabled" :label="label">
    <slot></slot>
  </el-radio>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  disabled?: boolean
  label?: string
  modelValue?: string | number | boolean
}

interface Emits {
  (e: 'update:model-value', value?: string | number | boolean): void
  (e: 'change', value?: string | number | boolean): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    emits('change', value)
  }
})
</script>

<style scoped></style>
