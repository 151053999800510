<template>
  <el-checkbox v-if="multiple" v-model="checkAll">全选</el-checkbox>
  <el-checkbox-group v-model="_modelValue" v-loading="loading">
    <el-checkbox v-for="item in normalizedData" :label="item.value">
      {{ item.label }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import { useMaintain, type MaintainAjaxConfig } from '@enocloud/hooks'
import { get, map } from 'lodash-es'

interface CheckedProps {
  label: string
  value: string
}

interface Props {
  ajax?: MaintainAjaxConfig
  disabled?: boolean
  modelValue?: any
  multiple?: boolean
  min?: number
  max?: number
  options?: unknown[]
  props?: Partial<CheckedProps>
  valueKey?: string
}

interface Emits {
  (e: 'update:model-value', value: unknown): void
  (e: 'change', value: unknown): void
}

const props = withDefaults(defineProps<Props>(), {})
const emits = defineEmits<Emits>()

const checkAll = computed({
  get: () => props.modelValue?.length === data.value?.length,
  set: (value) => {
    if (value) {
      emits(
        'update:model-value',
        normalizedData.value.map((item) =>
          normalizedProps.value.value === '' ? data.value.find((d) => get(d, props.valueKey ?? 'valueKey') === item.value) : item.value
        )
      )
    } else {
      emits('update:model-value', [])
    }
  }
})

const normalizedProps = computed<CheckedProps>(() => Object.assign({ label: 'label', value: 'value' }, props.props))

const normalizedData = computed(() => {
  return data.value.map((item) => {
    return Object.entries(normalizedProps.value).reduce((res, [key, value]) => {
      res[key] = get(item, value || props.valueKey)
      return res
    }, {} as any)
  })
})

const _modelValue = computed({
  get: () => {
    const key = normalizedProps.value.value || props.valueKey || 'value'
    if (props.multiple) {
      return key ? map(props.modelValue, key) : []
    } else {
      return props.modelValue ? [get(props.modelValue, key, props.modelValue)] : []
    }
  },
  set: (value) => {
    let localValue: any = null

    if (props.multiple) {
      localValue = value.map((item) => data.value.find((d) => get(d, normalizedProps.value.value || props.valueKey || 'value', d) === item))
    } else {
      let [oldValue, newValue] = value
      newValue ??= oldValue
      localValue = normalizedProps.value.value
        ? newValue
        : data.value.find((d) => get(d, normalizedProps.value.value || props.valueKey || 'value', d) === newValue)
    }

    emits('update:model-value', localValue)
    emits('change', localValue)
  }
})

const { data, loading } = useMaintain('checkbox', {
  ajax: props.ajax,
  data: toRef(props, 'options'),
  lazy: false
})
</script>
