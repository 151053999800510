<template>
  <el-switch v-model="_modelValue" :active-text="activeText" :active-value="activeValue" :inactive-value="inactiveValue"></el-switch>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  activeText?: string
  activeValue?: string
  inactiveValue?: string
  modelValue?: string | number | boolean
  placeholder?: string
}

interface Emits {
  (e: 'change', value?: string | number | boolean): void
  (e: 'update:model-value', value?: string | number | boolean): void
}

const props = defineProps<Props>()

const emits = defineEmits<Emits>()

let init = false
const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    if (init) emits('change', value)
    init = true
  }
})
</script>
