<template>
  <div class="relative h-full flex" v-click-outside="onClickOutside">
    <div
      v-for="(item, index) of data"
      :key="item.id"
      :id="item.id"
      ref="rootMenusRef"
      @click="onMenuClick($event, item, 'root', index)"
      :class="[
        'h-full px-4 flex items-center cursor-pointer hover:bg-gray-100 hover:font-medium hover:text-primary',
        activeRootMenu?.id === item.id && 'bg-gray-100'
      ]"
    >
      <div :class="['relative', activeRootMenu?.id === item.id && `enoch-menu__active`]">
        {{ item.message }}
      </div>
    </div>

    <div class="absolute z-9999 bg-white p-4" :style="popoverStyle">
      <div class="flex gap-12">
        <div v-for="item of trunkMenus" :key="item.id">
          <div :class="['text-xl font-medium hover:cursor-pointer']" @click="onMenuClick($event, item, 'trunk')">
            <span>{{ item.message }}</span>
          </div>

          <div class="max-h-100 overflow-auto scrollbar scrollbar-w-4px mt-4 flex flex-col gap-4 pr-10">
            <div v-for="inner of item.children">
              <span class="cursor-pointer hover:text-primary" @click="onMenuClick($event, inner, 'leaf')">{{ inner.message }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { ClickOutside as vClickOutside } from 'element-plus'
import { useRouter } from 'vue-router'
import { useMenus, type MenuConfig } from '@enocloud/hooks'
import { type CSSProperties } from 'vue'

const router = useRouter()
const { data } = useMenus()

const popoverVisible = ref(false)
const activeRootIndex = ref<number>(0)

const rootMenusRef = ref<HTMLDivElement[]>([])
const rootMenuRect = ref<DOMRect>(
  DOMRect.fromRect({
    x: 0,
    y: 0
  })
)

const popoverStyle = computed<CSSProperties>(() => {
  return {
    display: popoverVisible.value ? 'block' : 'none',
    left: `${rootMenuRect.value.left - rootMenuRect.value.width - 16}px`,
    top: `${rootMenuRect.value.height}px`,
    'box-shadow': '0px 6px 18px rgba(0, 0, 0, 0.16)'
  }
})

const activeRootMenu = computed(() =>
  data.value.find((item) => item.id === (router.currentRoute.value.name as string).split('-')[0].toLocaleUpperCase())
)

const trunkMenus = computed(() => data.value[activeRootIndex.value]?.children ?? [])

const onMenuClick = (e: Event, menu: MenuConfig, level: string, index?: number) => {
  if (level === 'root') {
    activeRootIndex.value = index!
    const rootMenuRef = rootMenusRef.value.find((item) => item.getAttribute('id') === menu.id)!
    rootMenuRect.value = rootMenuRef?.getBoundingClientRect()
    popoverVisible.value = Boolean(menu.children && menu.children.length)
  } else {
    popoverVisible.value = false
  }

  if ((!menu.children || !menu.children.length) && menu.path) router.push(menu.path)
}

const onClickOutside = () => {
  popoverVisible.value = false
}
</script>

<style scoped>
.enoch-menu__active::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -6px;
  left: 2px;
  right: 2px;
  height: 4px;
  border-radius: 4px;
  background: var(--el-color-primary);
}
</style>
