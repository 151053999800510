<template>
  <el-popover ref="popoverRef" :visibl="visible" :trigger="trigger" :placement="placement" :width="width">
    <slot></slot>

    <template #reference>
      <slot name="reference"></slot>
    </template>
  </el-popover>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { Placement, TooltipTriggerType, PopoverInstance } from 'element-plus'

interface Props {
  placement?: Placement
  trigger?: TooltipTriggerType
  visible?: boolean
  width?: string | number
}

defineProps<Props>()

const popoverRef = ref<PopoverInstance | null>()

defineExpose({
  hide: () => popoverRef.value?.hide()
})
</script>
