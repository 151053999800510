<template>
  <div class="relative">
    <el-tabs :modelValue="_modelValue" :tab-position="tabPosition" @tab-click="onTabClick">
      <slot></slot>
    </el-tabs>
    <el-space class="absolute top-0 right-0" :size="24">
      <slot name="operation"></slot>
    </el-space>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { TabsPaneContext, TabsProps } from 'element-plus'

interface Props {
  modelValue?: string | number
  tabPosition?: TabsProps['tabPosition']
}

interface Emits {
  (e: 'change', value: string | number | undefined): void
  (e: 'tab-click', pane: TabsPaneContext, ev: Event): void
  (e: 'update:model-value', value: string | number | undefined): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    emits('change', value)
  }
})

const onTabClick = (pane: TabsPaneContext, ev: Event) => {
  _modelValue.value = pane.paneName
  emits('tab-click', pane, ev)
}
</script>
