<template>
  <el-checkbox-group v-model="_modelValue" :disabled="disabled">
    <slot></slot>
  </el-checkbox-group>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { CheckboxGroupValueType } from 'element-plus'

interface Props {
  modelValue?: CheckboxGroupValueType
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), { modelValue: () => [] })

interface Emits {
  (e: 'update:model-value', value?: CheckboxGroupValueType): void
  (e: 'change', value?: CheckboxGroupValueType): void
}

const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    emits('change', value)
  }
})
</script>
