<template>
  <el-cascader
    v-model="_modelValue"
    ref="cascaderRef"
    :allow-create="allowCreate"
    :filterable="_filterable"
    :options="normalizedOptions"
    :placeholder="placeholder"
    :props="cascaderProps"
    :teleported="teleported"
    @focus="onCascaderFocus"
    @blur="onCascaderBlur"
  ></el-cascader>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect, onMounted } from 'vue'
import { ElCascader } from 'element-plus'
import { type MaintainAjaxConfig } from '@enocloud/hooks'
import { ajax } from '@enocloud/utils'
import { get, isArray, isObject, concat, find, isEqual, isPlainObject, isFunction, set, cloneDeep } from 'lodash-es'

import type { CascaderOption, CascaderProps, CascaderNodeValue } from 'element-plus'

type ICascaderProps = Omit<CascaderProps, 'leaf'> & { valueKey?: string; leaf?: string | ((option: any) => boolean) }

interface Props {
  ajax?: MaintainAjaxConfig | MaintainAjaxConfig[]
  allowCreate?: boolean
  beforeFilter?: (value: string) => boolean | Promise<any>
  checkStrictly?: boolean
  filterable?: boolean
  teleported?: boolean
  props?: Partial<ICascaderProps> | Partial<ICascaderProps>[]
  options?: CascaderOption[]
  placeholder?: string
  lazy?: boolean
  modelValue?: any[]
  valueKey?: string
  multiple?: boolean
  debug?: boolean
}

interface Emits {
  (e: 'update:model-value', value: unknown): void
  (e: 'change', value: unknown): void
}

const defaultProps: ICascaderProps = { label: 'label', value: 'value', children: 'children', valueKey: 'value', leaf: 'isLeaf' }

const props = withDefaults(defineProps<Props>(), { placeholder: '请选择' })
const emits = defineEmits<Emits>()

class Option<T extends Record<string, any> = Record<string, any>> implements CascaderOption {
  [x: string]: unknown
  label?: string
  value?: CascaderNodeValue
  children: Option<T>[] = []
  disabled?: boolean
  leaf?: boolean

  emitValue?: T | CascaderNodeValue
  parent: Option<T> | null = null

  constructor(
    private readonly data: T,
    level: number,
    props: Required<ICascaderProps>[],
    parent: Option<T> | null = null
  ) {
    this.data = data
    const _props = props[level] ?? props[0]
    const { label, value, valueKey, children, leaf } = _props
    this.label = get(data, label)
    this.value = get(data, value || valueKey)
    this.emitValue = value === '' ? data : get(data, value)
    const _children = get(data, children)
    this.children = _children ? _children.map((child: any) => new Option(child, level + 1, props, this)) : []

    if (isFunction(leaf)) {
      this.leaf = leaf(data)
    } else {
      this.leaf = Boolean(this.children && !this.children.length) || get(data, leaf, false)
    }

    this.parent = parent
  }
}

const cascaderRef = ref<InstanceType<typeof ElCascader> | null>()

const _filterable = computed(() => props.filterable || props.allowCreate)

const loading = ref(false)
const data = ref<Option[]>([])

const getData = async () => {
  loading.value = true
  if (!props.ajax) return
  const ajaxs = isArray(props.ajax) ? props.ajax : [props.ajax]
  const _ajax = ajaxs[0]
  if (!_ajax) return
  try {
    const _params: any = { payload: {}, paths: [] }
    _ajax.params?.(_params)
    const res: any[] = ((await ajax(_ajax.action, _params)) as any).data
    data.value = res.map((item, index) => new Option(item, index, normalizedProps.value))
  } catch (err) {
  } finally {
    loading.value = false
  }
}

const lazyload: CascaderProps['lazyLoad'] = async (node, resolve) => {
  const { level } = node
  if (!props.ajax) {
    resolve([])
    return
  }
  const ajaxs = isArray(props.ajax) ? props.ajax : [props.ajax]
  const _ajax = ajaxs[level]
  try {
    const _params: any = { payload: {}, paths: [] }
    _ajax.params?.(_params, node.pathValues)
    const data: any[] = ((await ajax(_ajax.action, _params)) as any).data
    resolve(data.map((item) => new Option(item, level, normalizedProps.value)))
  } catch (err) {
  } finally {
  }
}

const cascaderProps = computed<CascaderProps>(() => {
  const _props: CascaderProps = {
    multiple: props.multiple,
    checkStrictly: props.checkStrictly,
    lazy: props.lazy,
    label: 'label',
    value: 'value',
    children: 'children'
  }

  if (_props.lazy) {
    _props.lazyLoad = lazyload
  }
  return _props
})

const resolvedProps = (props: ICascaderProps) => {
  return Object.assign({}, defaultProps, props) as Required<ICascaderProps>
}
const normalizedProps = computed<Required<ICascaderProps>[]>(() => {
  const _props = props.props ?? Object.assign({}, defaultProps)
  return isArray(_props) ? _props.map(resolvedProps) : [resolvedProps(_props)]
})

const pendingData = ref<any[]>([])
const normalizedOptions = computed<Option[]>(() =>
  concat<Option>(
    props.allowCreate && inputValue.value
      ? [new Option({ label: inputValue.value, value: inputValue.value }, 0, [{ label: 'label', value: 'value' }] as Required<ICascaderProps>[])]
      : [],
    data.value as Option[],
    pendingData.value.map((item, index) => new Option(item, index, normalizedProps.value))
  )
)

const getItemByKey = (source: Option[], value: any, by: 'value' | 'label' = 'value'): Option | null => {
  let res: any = null
  res = find(source, [by, value]) as Option | null
  if (!res) {
    for (const item of source) {
      if (!res) res = getItemByKey(item.children, value, by)
    }
  }
  return res
}

const _modelValue = computed({
  get: () => {
    return isArray(props.modelValue)
      ? props.modelValue.map((item, index) => {
          const _props = normalizedProps.value[index] ?? normalizedProps.value[0]
          const { value, valueKey } = _props
          if (isArray(item)) {
            return item.map((inner) => {
              return isObject(inner) ? (value === '' ? get(inner, valueKey) : get(inner, value)) : inner
            })
          } else if (isPlainObject(item)) {
            return value === '' ? get(item, valueKey) : get(item, value)
          } else {
            return item
          }
        })
      : []
  },
  set: (value) => {
    let emitValue: any[] = []
    if (isArray(value)) {
      emitValue = value.reduce((res, v, index) => {
        const _props = normalizedProps.value[index] ?? normalizedProps.value[0]
        const { label, value, valueKey } = _props

        if (props.multiple && isArray(v)) {
          res.push(
            v.reduce((a, item) => {
              if (value === '') a.push({ [`${valueKey}`]: item, [`${label}`]: getItemByKey(normalizedOptions.value, item)?.label })
              else a.push(item)
              return a
            }, [])
          )
        } else {
          if (value === '') res.push({ [`${valueKey}`]: v, [`${label}`]: getItemByKey(normalizedOptions.value, v)?.label })
          else res.push(v)
        }
        return res
      }, [])
    }
    console.log(emitValue)
    emits('update:model-value', emitValue)
    emits('change', emitValue)
  }
})

const inputValue = ref('')
const onCascaderFocus = () => {
  const focusedElement = document.activeElement
  if (focusedElement?.tagName === 'INPUT') {
    ;(focusedElement as HTMLInputElement).addEventListener('input', (e) => {
      inputValue.value = (e.target as any).value
    })
  }
}

const onCascaderBlur = () => {
  // inputValue.value = ''
}

let pending: any = {}

const addPendingValue = (value: any[]) => {
  for (const [i, v] of value.entries()) {
    const _props = normalizedProps.value[i] ?? normalizedProps.value[0]
    if (!i) {
      set(pending, _props.label, _props.value === '' ? get(v, _props.label) : v)
      pendingData.value.push(pending)
    } else {
      let _pending = set(cloneDeep(pending), _props.label, _props.value === '' ? get(v, _props.label) : v)
      set(pending, _props.children, [_pending])
      pending = _pending
    }
  }
}

watchEffect(() => {
  if (props.options) {
    data.value = props.options.map((item, index) => new Option(item, index, normalizedProps.value))
  }

  if (props.modelValue && isArray(props.modelValue) && data.value.length) {
    for (const [index, item] of props.modelValue.entries()) {
      const _props = normalizedProps.value[index] ?? normalizedProps.value[0]
      if (!index) {
        const exist =
          _props.value === ''
            ? find(data.value, (option) => isEqual(get(option.value, _props.valueKey), get(item, _props.valueKey)))
            : find(data.value, (option) => isEqual(option.value, item))

        if (!exist) {
          pendingData.value = []
          addPendingValue(props.modelValue)
        }
      }
    }
  }
})

onMounted(() => {
  if (!props.lazy && !props.options) getData()
})

defineExpose({ data: computed(() => normalizedOptions.value), focus: () => {} })
</script>
