<template>
  <el-rate v-model="_modelValue" :disabled="disabled"> </el-rate>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  modelValue?: number
  disabled?: boolean
}

interface Emits {
  (e: 'update:model-value', value: number): void
  (e: 'change', value: number): void
}

const props = withDefaults(defineProps<Props>(), {})

const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value ?? 0)
    emits('change', value ?? 0)
  }
})
</script>
