<template>
  <el-cascader v-model="_modelValue" :props="_props" ref="cascaderRef" placeholder="请选择"> </el-cascader>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { ajax } from '@enocloud/utils'

import type { CascaderOption, CascaderProps, CascaderInstance, CascaderNode } from 'element-plus'

type AreaDto = EnocloudCommonDefinitions['AreaDto']

interface Props {
  modelValue?: AreaDto
}

interface Emits {
  (e: 'update:model-value', value: AreaDto): void
  (e: 'change', value: AreaDto): void
}

const props = withDefaults(defineProps<Props>(), {})
const emits = defineEmits<Emits>()

const cascaderRef = ref<CascaderInstance | null>()

const getCodesByAreaDto = (area?: AreaDto) => {
  let result: number[] = []
  if (area) {
    result.unshift(area.code!)
    if (area?.parent) result.unshift(...getCodesByAreaDto(area.parent))
  }
  return result
}

const getAreaByNode = (node: CascaderNode) => {
  const result = {} as AreaDto
  result.name = node.label
  result.code = node.value as number
  if (node.parent) result.parent = getAreaByNode(node.parent)
  return result
}

const _modelValue = computed({
  get: () => getCodesByAreaDto(props.modelValue),
  set: () => {
    const nodes = cascaderRef.value?.getCheckedNodes?.(true)
    if (nodes?.length) {
      const value = getAreaByNode(nodes[0])
      emits('update:model-value', value)
      emits('change', value)
    }
  }
})

const _props: CascaderProps = {
  lazy: true,
  lazyLoad: async (node, resolve) => resolve(await getData(node.value as number | undefined))
}

const getData = async (parentCode?: number) => {
  try {
    const res = await ajax('GET /enocloud/common/area/children', { payload: { parentCode } })
    return Promise.resolve(
      res.data.map((item) => {
        return {
          label: item.name,
          leaf: !item.hasChildren,
          value: item.code
        } as CascaderOption
      })
    )
  } catch (err) {
    return Promise.reject(err)
  }
}
</script>
