<template>
  <el-tooltip ref="tooltipRef" :placement="placement" :disabled="disabled" :content="content">
    <template v-if="$slots.content" #content>
      <slot name="content"></slot>
    </template>

    <slot></slot>
  </el-tooltip>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { ElTooltip } from 'element-plus'

interface Props {
  content?: string
  disabled?: boolean
  placement?: 'top' | 'bottom' | 'right' | 'left'
}

withDefaults(defineProps<Props>(), { placement: 'top' })

const tooltipRef = ref<InstanceType<typeof ElTooltip> | null>(null)

defineExpose({
  popperRef: computed(() => tooltipRef.value?.popperRef),
  hide: () => tooltipRef.value?.hide()
})
</script>
