<template>
  <div class="flex flex-col" v-loading="loading">
    <el-table
      ref="tableRef"
      :data="data"
      :height="_height"
      :border="border"
      :rowKey="rowKey"
      :row-style="rowStyle"
      :showHeader="showHeader"
      :show-summary="_showSummary"
      :summary-method="summary ? localSummaryMethod : summaryMethod"
      :span-method="spanMethod"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      @header-dragend="onHeaderDragend"
      @selection-change="onSelectionChange"
      @current-change="onCurrentChange"
      @row-click="onRowclick"
      @sort-change="onSortChange"
    >
      <slot></slot>
    </el-table>

    <div v-if="showItemCount" class="flex justify-end mt-4">
      <span class="text-sm text-gray">{{ paging?.itemCount }}项</span>
    </div>

    <div v-if="pagination" class="flex justify-end items-baseline">
      <el-button v-if="summaryBtnVisible" type="primary" link :disabled="loading" @click="localShowSummary = !localShowSummary">
        {{ localShowSummary ? '隐藏' : '查看' }}合计
      </el-button>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="paging.pageSize"
        :total="paging?.itemCount"
        :current-page="paging.pageIndex"
        :page-sizes="[20, 40, 60, 80]"
        @update:current-page="onUpdateCurrentPage"
        @update:page-size="onUpdatePageSize"
        class="flex justify-end !py-0 mt-4 !px-6"
      ></el-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { throttle } from 'lodash-es'
import { formatMoney } from '@enocloud/utils'
import type { SummaryMethod, TableColumnCtx, TableInstance, ColumnStyle } from 'element-plus'

interface SortParamData<T = unknown> {
  column: TableColumnCtx<T>
  order: 'descending' | 'ascending' | null
  prop: string
}

export type SortEvent<T = unknown> = (data: SortParamData<T>) => void

export interface TablePaging {
  pageIndex: number
  pageSize: number
  itemCount: number
  pageCount: number
}

export interface TableSpanMethodData<R = unknown> {
  row: R | null
  rowIndex: number
  column: TableColumnCtx<R>
  columnIndex: number
}

interface Props {
  addition?: Record<string, any>
  autoload?: boolean
  border?: boolean
  columns?: any[]
  data?: unknown[]
  height?: number
  loading?: boolean
  method?: Function
  pagination?: boolean
  paging?: TablePaging
  rowKey?: string
  rowStyle?: ColumnStyle<unknown>
  showHeader?: boolean
  showItemCount?: boolean
  showSummary?: boolean
  summary?: unknown
  summaryMethod?: SummaryMethod<unknown>
  spanMethod?: (data: TableSpanMethodData) =>
    | number[]
    | {
        rowspan: number
        colspan: number
      }
    | undefined
}

interface Emits {
  (e: 'header-dragend', newWidth: TableColumnCtx<unknown>['width'], width: TableColumnCtx<unknown>['width'], column: TableColumnCtx<unknown>): void
  (e: 'selection-change', value: unknown[]): void
  (e: 'current-change', value: unknown): void
  (e: 'row-click', value: unknown): void
  (e: 'sort-change', data: SortParamData): void
}

const emits = defineEmits<Emits>()

const props = withDefaults(defineProps<Props>(), {
  autoload: true,
  border: true,
  data: () => [],
  paging: () => {
    return {
      pageIndex: 1,
      pageSize: 20,
      pageCount: 0,
      itemCount: 0
    }
  },
  showHeader: true
})

const tableRef = ref<TableInstance | null>()

const _height = computed(() => {
  let h = 250
  if (props.height) h = props.height
  if (props.pagination) h -= 48
  if (props.showItemCount) h -= 36
  return h <= 250 ? undefined : h
})

const localShowSummary = ref(false)

const summaryBtnVisible = computed(() => props.paging.itemCount! >= 500 && props.showSummary)

const _showSummary = computed(() => props.showSummary && (localShowSummary.value || !summaryBtnVisible.value))

const updatePaging = (payload: Partial<TablePaging>, invokedByScroll: boolean = false) => {
  Object.assign(props.paging, payload)
  props.method?.({ addition: { ...props.paging, ...payload, ...props.addition }, invokedByPagination: true, invokedByScroll })
}

const onUpdateCurrentPage = (pageIndex: number) => updatePaging({ pageIndex }, false)
const onUpdatePageSize = (pageSize: number) => updatePaging({ pageSize }, false)

const localSummaryMethod: SummaryMethod<unknown> = (ctx) => {
  return ctx.columns
    .map((item) => item.property)
    .map((key) => {
      if (key) {
        const value = (props.summary as Record<string, any>)[key]
        const column = props.columns?.find((item) => item.columnName?.prop === key)
        return column?.columnName?.summable
          ? column?.columnName?.dataType === 'CURRENCY'
            ? formatMoney(value)
            : value || column?.columnName?.placeholder
          : column?.columnName?.placeholder
      } else {
        return '-'
      }
    })
}

const onHeaderDragend = (newWidth: TableColumnCtx<unknown>['width'], width: TableColumnCtx<unknown>['width'], column: TableColumnCtx<unknown>) => {
  emits('header-dragend', newWidth, width, column)
}

const onSelectionChange = (value: unknown[]) => emits('selection-change', value)
const onCurrentChange = (value: unknown) => emits('current-change', value)
const onRowclick = (value: unknown) => emits('row-click', value)

const onSortChange: SortEvent = (value) => emits('sort-change', value)

let scrollWrapper: Element | null
const distance = 0
const delay = 200
const handleTableBodyScrollY = throttle(() => {
  if (!scrollWrapper) return
  const shouldTrigger = scrollWrapper.scrollHeight - scrollWrapper.scrollTop - scrollWrapper.clientHeight <= distance
  if (shouldTrigger && props.autoload) {
    let { pageIndex, pageCount } = props.paging
    if (pageIndex < pageCount) {
      pageIndex += 1
      updatePaging({ pageIndex }, true)
    }
  }
}, delay)

onMounted(() => {
  if (tableRef.value) {
    scrollWrapper = tableRef.value.scrollBarRef.wrapRef
    if (!scrollWrapper) return
    scrollWrapper.addEventListener('scroll', handleTableBodyScrollY)
  }
})

defineExpose({ tableRef })
</script>

<style>
/* .el-table__body-wrapper .el-scrollbar__view {
  width: 100%;
}
.el-table__header,
.el-table__body {
  min-width: 100%;
} */

.el-table__body .el-table__cell {
  border-left: 0 !important;
  border-right: 0 !important;
}

.el-table__footer-wrapper tbody td.el-table__cell {
  background-color: #eeeffb;
}
</style>
