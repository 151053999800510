<template>
  <div ref="formWrappRef">
    <en-form label-position="right" :label-width="labelWidth" inline :model="model" ref="formRef" class="form-query">
      <form-item-render :children="renderChildren"></form-item-render>

      <form-item-render :children="collapseChildren"></form-item-render>

      <en-form-item>
        <div class="flex">
          <button-ajax type="primary" :method="method">查询</button-ajax>
          <button-ajax v-if="showReset" type="default" :method="reset">重置</button-ajax>
          <en-button v-if="collapse && collapseChildren.length" type="primary" link :icon="IArrowDownBold" @click="collapse = false">展开</en-button>
          <en-button v-if="!collapse && collapseChildren.length" type="primary" link :icon="IArrowUpBold" @click="collapse = true">收起</en-button>
        </div>
      </en-form-item>
    </en-form>
  </div>
</template>

<script setup lang="ts">
import { h, defineComponent, computed, ref, useSlots, onMounted, type VNode } from 'vue'
import { ArrowUpBold as IArrowUpBold, ArrowDownBold as IArrowDownBold } from '@element-plus/icons-vue'
import { EnForm, EnFormItem, EnButton, ButtonAjax } from '@components/index'
import { useResizeObserver } from '@enocloud/hooks'
import { isString } from 'lodash-es'

interface Props {
  labelWidth?: number | string
  model?: Record<string, any>
  method?: (...args: any[]) => any
  init?: Function
  showReset?: boolean
}
const props = withDefaults(defineProps<Props>(), { labelWidth: 100, showReset: true })
const formRef = ref<InstanceType<typeof EnForm> | null>()
const formWrappRef = ref<HTMLElement | null>()
const { rect } = useResizeObserver(formWrappRef)
const formItemWidth = computed(() => (isString(props.labelWidth) ? parseFloat(props.labelWidth) : props.labelWidth) + 220 + 32)
const maxFormItemCount = computed(() => Math.floor(rect.value.width / formItemWidth.value) - 1)

const slots = useSlots()

const children = computed(() => slots.default?.() ?? [])
const renderChildren = computed(() => [...children.value].splice(0, maxFormItemCount.value))
const collapseChildren = computed(() => (collapse.value ? [] : [...children.value].splice(renderChildren.value.length)))
const collapse = ref(true)

const formItemRender = defineComponent({
  props: { children: Array as PropType<VNode[]> },
  setup(props) {
    return () => [...(props.children ?? [])].map((c) => h(c))
  }
})

let initData: Record<string, any>

const reset = () => {
  if (props.init) props.init()
  else Object.assign(props.model ?? {}, initData)
  return props.method?.()
}

onMounted(() => {
  initData = Object.assign({}, props.model)
})
</script>

<style>
.form-query .el-form-item__content {
  width: 220px;
}
</style>
