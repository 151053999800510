<template>
  <el-drawer
    v-model="_modelValue"
    :size="size"
    :direction="direction"
    :show-close="false"
    :with-header="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @open="$emit('open')"
    @opened="$emit('opened')"
    @close="$emit('close')"
  >
    <div class="h-full flex flex-col gap-4">
      <div class="flex items-center gap-2">
        <i-close class="w-4 h-4 cursor-pointer hover:text-primary" @click="onClose"></i-close>
        <span>{{ title }}</span>
      </div>

      <div class="flex-1 overflow-auto">
        <el-scrollbar wrap-class="h-full" view-class="h-full pr-5">
          <slot></slot>
        </el-scrollbar>
      </div>

      <div v-if="$slots.footer" class="flex justify-end">
        <slot name="footer"></slot>
      </div>
    </div>
  </el-drawer>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import { Close as IClose } from '@element-plus/icons-vue'
import type { DrawerProps } from 'element-plus'

interface Props {
  modelValue?: boolean
  title?: string
  size?: number | string
  direction?: DrawerProps['direction']
}

interface Emits {
  (e: 'update:modelValue', value: boolean): void
  (e: 'open'): void
  (e: 'opened'): void
  (e: 'close'): void
}

const props = withDefaults(defineProps<Props>(), {
  size: 378
})

const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emits('update:modelValue', value)
})

const onClose = () => {
  _modelValue.value = false
  emits('close')
}
</script>

<style>
.el-drawer {
  --el-drawer-padding-primary: 24px;
  color: var(--el-text-color-primary);
}

.el-drawer__header {
  margin-bottom: 0;
}
</style>
