<template>
  <div class="h-16 bg-white px-6 flex items-center gap-4 relative">
    <router-link to="/">
      <img src="@components/assets/logo.png" alt="enoch-logo" class="w-10" />
    </router-link>

    <div class="flex-1 h-full">
      <enoch-menu></enoch-menu>
    </div>

    <div class="flex items-center gap-6">
      <en-tooltip :visible="tooltipVisible" ref="tooltipRef" effect="light">
        <template #content>
          <en-scrollbar :height="300" class="w-70" v-loading="loading" view-class="relative flex flex-col">
            <div
              v-for="item of queryOptions"
              class="cursor-pointer rounded flex items-center gap-3 px-1 py-2 hover:bg-[var(--el-color-primary-light-8)]"
              @click="onQueryMenuItemClick(item)"
            >
              <div v-if="item.tag" class="rounded-lg px-2 py-1 bg-gray-100 flex items-center text-gray-500">{{ item.tag }}:</div>
              <div class="text-gray-700">{{ item.label }}</div>
            </div>

            <div v-if="!options.length && queryText" class="pt-10 flex justify-center text-xs text-gray-500">未找到搜索结果</div>
          </en-scrollbar>
        </template>

        <div
          v-click-outside:[popperPaneRef]="onClickOutside"
          class="w-70 p-1 rounded flex items-center"
          :style="{
            'box-shadow': `0 0 0 1px var(${isFocus ? '--el-color-primary' : '--el-border-color'}) inset`
          }"
        >
          <en-tag v-if="queryPrimary">{{ queryPrimary?.tag }}</en-tag>
          <input
            v-model="queryText"
            ref="queryInputRef"
            text
            class="flex-1 border-none outline-0 p-0 ml-3 text-xs h-6 bg-transparent"
            placeholder="搜索"
            @focus="onInputFocus"
            @blur="onInputBlur"
            @keydown.delete="onInputDelete"
          />
        </div>
      </en-tooltip>

      <el-button :icon="Bell" link @click="store.openDownload"></el-button>

      <switch-branch></switch-branch>

      <en-popover trigger="click" :offset="0" :show-arrow="false" pure placement="bottom-end" width="300" popper-style="--el-popover-padding: 0">
        <div class="p-4 flex flex-col divider-y gap-4">
          <div class="flex flex-col">
            <span class="text-lg font-medium">{{ store.user.name }}</span>
            <span class="text-sm text-gray-200">{{ store.user.cellphone }}</span>
          </div>

          <div class="flex items-center gap-2">
            <en-icon>
              <icon-location></icon-location>
            </en-icon>
            <span>{{ store.user.branch?.shortName }}</span>
          </div>

          <div class="flex items-center gap-2 cursor-pointer" @click="onHelpClick">
            <en-icon>
              <icon-notebook></icon-notebook>
            </en-icon>
            <span>帮助文档</span>
          </div>

          <div class="flex items-center gap-2 cursor-pointer" @click="onLogoutClick">
            <en-icon>
              <icon-close></icon-close>
            </en-icon>
            <span>退出</span>
          </div>
        </div>

        <template #reference>
          <en-button link>{{ store.user.name }}</en-button>
        </template>
      </en-popover>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { get, set } from 'lodash-es'
import { ArrowDown, Bell, Location as IconLocation, Notebook as IconNotebook, Close as IconClose } from '@element-plus/icons-vue'
import { useStore, factory, ajax, useRouter } from '@enocloud/utils'

import { EnTooltip, EnIcon, EnDropdown, EnDropdownItem, EnScrollbar, EnTag, EnPopover, EnButton } from '@components/index'
import EnochMenu from '@components/project/enoch-menu.vue'
import { computed, customRef } from 'vue'

interface QueryMenu {
  label: string
  tag?: string
  type?: string
  code?: string
  path?: string
  item?: any
  onSelect?: (data: QueryMenu) => void
}

const store = useStore()
const router = useRouter()

const SwitchBranch = factory({
  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/security/branch/available',
            data: 'array'
          },
          submit: {
            action: 'PUT /enocloud/security/branch/:branchId'
          }
        }
      }
    }
  },

  render(h) {
    return h(
      EnDropdown,
      {
        trigger: 'click',
        onCommand: async (id) => {
          await this.table.submit({ paths: [id] })
          location.reload()
        }
      },
      {
        default: () => h('div', { class: 'flex items-center gap-2' }, [h('span', this.store.user.branch?.shortName)]),
        dropdown: () => {
          return this.table.data.map((item) => {
            return h(EnDropdownItem, { command: `${item.id}` }, () => item.shortName)
          })
        }
      }
    )
  },

  mounted() {
    this.table.get()
  }
})

let text = ''
let timeout: NodeJS.Timeout | null
const queryText = customRef((track, trigger) => {
  return {
    get() {
      track()
      return text
    },
    set(newValue) {
      timeout && clearTimeout(timeout)
      timeout = setTimeout(() => {
        text = queryPrimary.value ? newValue : ''
        if (queryPrimary.value) getOptions(newValue)
        trigger()
      }, 300)
    }
  }
})

const isFocus = ref(false)
const tooltipVisible = ref(false)
const loading = ref(false)
const queryInputRef = ref<HTMLInputElement | null>(null)
const queryPrimary = ref<QueryMenu | null>(null)
const tooltipRef = ref<InstanceType<typeof EnTooltip> | null>(null)
const popperPaneRef = computed(() => tooltipRef.value?.popperRef?.contentRef)

const menus: QueryMenu[] = [
  {
    tag: '客户',
    label: '客户对象',
    code: 'customer',
    onSelect(data: QueryMenu) {
      router.push('/client/customer/vehicle', (vm) => {
        vm.customer.name.click(data)
      })
    }
  },
  {
    tag: '车辆',
    label: '客户车辆',
    code: 'vehicle',
    onSelect(data: QueryMenu) {
      router.push('/client/customer/vehicle', (vm) => {
        vm.vehicle.name.click(data)
      })
    }
  },
  {
    tag: '维修单',
    label: '客户维修单',
    code: 'service',
    onSelect(data: QueryMenu) {
      router.push('/service/business/workorder', (vm) => {
        vm.form.data.id = data.item.id
        vm.form.get()
      })
    }
  },
  {
    tag: '估价单',
    label: '客户估价单',
    code: 'quotation',
    onSelect(data: QueryMenu) {
      router.push('/service/business/quotation', (vm) => {
        vm.form.data.id = data.item.id
        vm.form.get()
      })
    }
  },
  {
    tag: '检测单',
    label: '客户检测单',
    code: 'inspection',
    onSelect(data: QueryMenu) {
      router.push('/service/business/inspection', (vm) => {
        vm.form.data.id = data.item.id
        vm.form.get()
      })
    }
  },
  {
    tag: '应收款',
    label: '客户应收款',
    code: 'receivable',
    onSelect(data: QueryMenu) {
      router.push('/settlement/receivable', (vm) => {
        vm.form.data.id = data.item.id
        vm.form.get()
      })
    }
  }
]

const options = ref<QueryMenu[]>([])

const onInputFocus = () => {
  isFocus.value = true
  tooltipVisible.value = true
}

const onInputBlur = () => {
  isFocus.value = false
}

const normalizedOptions = <O extends QueryMenu>(data: any[], _options: O): Array<O> => {
  return data.map((item) =>
    Object.entries(_options).reduce(
      (res, [key, value]) => {
        set(res, key, get(item, value, ''))
        return res
      },
      { item } as O
    )
  )
}

const queryOptions = computed(() => (queryPrimary.value ? options.value : menus))

const getOptions = async (quickSearch: string = '') => {
  loading.value = true
  try {
    switch (queryPrimary.value?.code) {
      case 'customer':
        options.value = normalizedOptions((await ajax('GET /enocloud/common/customer', { payload: { rsexp: 'id,name', quickSearch } })).data, {
          label: 'name'
        })
        break
      case 'vehicle':
        options.value = normalizedOptions((await ajax('GET /enocloud/common/vehicle', { payload: { rsexp: 'id,plateNo', quickSearch } })).data, {
          label: 'plateNo'
        })
        break
      case 'service':
        options.value = normalizedOptions((await ajax('GET /enocloud/service/query', { payload: { rsexp: 'id,serialNo', quickSearch } })).data, {
          label: 'serialNo'
        })
        break
      case 'quotation':
        options.value = normalizedOptions(
          (await ajax('GET /enocloud/service/quotation/query', { payload: { rsexp: 'id,serialNo', quickSearch } })).data,
          { label: 'serialNo' }
        )
        break
      case 'inspection':
        options.value = normalizedOptions(
          (await ajax('GET /enocloud/common/vehicle/inspection/report', { payload: { rsexp: 'id,serialNo', quickSearch } })).data,
          { label: 'serialNo' }
        )
        break
      case 'receivable':
        options.value = normalizedOptions(
          (await ajax('GET /enocloud/settlement/receivable/query', { payload: { rsexp: 'id,serialNo', quickSearch } })).data,
          { label: 'serialNo' }
        )
        break
    }
  } finally {
    setTimeout(() => (loading.value = false), 200)
  }
}

const onQueryMenuItemClick = (item: QueryMenu) => {
  if (!queryPrimary.value) {
    queryPrimary.value = item
    options.value = []
    getOptions()
  } else {
    queryPrimary.value.onSelect?.(item)
  }
}

const onInputDelete = () => {
  if (queryText.value) return
  queryPrimary.value = null
}

const onClickOutside = () => {
  tooltipVisible.value = false
  tooltipRef.value?.hide()
}

const onHelpClick = () => {
  window.open('http://helps.enoch-car.com')
}

const onLogoutClick = async () => {
  await ajax('POST /enocloud/sso/security/logout')
  window.location.reload()
}
</script>
