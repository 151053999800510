<template>
  <template v-if="collapseTagsPanel">
    <div class="w-full flex flex-col gap-2">
      <en-select
        v-model="_modelValue"
        ref="selectRef"
        :clearable="clearable"
        :allow-create="allowCreate"
        :collapse-tags="collapseTags"
        :collapse-tags-tooltip="collapseTagsTooltip"
        :value-key="valueKey"
        :remote="remote"
        :loading="loading"
        :filterable="filterable || remote || allowCreate"
        :remote-method="remoteMethod"
        :disabled="disabled"
        :default-first-option="defaultFirstOption"
        :multiple="multiple"
        :teleported="teleported"
        :placeholder="placeholder"
        :class="$attrs.class"
        @focus="onSelectInputFocus"
      >
        <en-option v-for="item of normalizedData" :label="item.label" :value="item.value" :disabled="item.disabled">
          <slot :option="item.data"></slot>
        </en-option>
        <slot name="append"></slot>
      </en-select>
      <div v-if="isArray(_modelValue)" class="px-4 py-2 border border-solid border-gray-200 flex flex-wrap gap-2">
        <en-tag v-for="item of _modelValue" :closable="!disabled" @close="onCollapseTagsClose(item)">
          {{ isFunction(normalizedProps.label) ? normalizedProps.label(item) : get(item, normalizedProps.label) }}
        </en-tag>
        <span v-if="!_modelValue.length" class="text-xs text-gray-400">暂无数据</span>
      </div>
    </div>
  </template>

  <template v-else>
    <en-select
      v-model="_modelValue"
      ref="selectRef"
      :clearable="clearable"
      :allow-create="allowCreate"
      :collapse-tags="collapseTags"
      :collapse-tags-tooltip="collapseTagsTooltip"
      :value-key="valueKey"
      :remote="remote"
      :loading="loading"
      :filterable="filterable || remote || allowCreate"
      :remote-method="remoteMethod"
      :disabled="disabled"
      :default-first-option="defaultFirstOption"
      :multiple="multiple"
      :teleported="teleported"
      :placeholder="placeholder"
      :class="$attrs.class"
      @focus="onSelectInputFocus"
      @blur="onSelectInputBlur"
    >
      <en-option v-for="item of normalizedData" :label="item.label" :value="item.value" :disabled="item.disabled">
        <slot :option="item.data"></slot>
      </en-option>

      <template v-if="$slots.empty">
        <slot name="empty"></slot>
      </template>
      <slot name="append"></slot>
    </en-select>
  </template>
</template>

<script setup lang="ts">
import { computed, toRef, ref, watch } from 'vue'
import { concat, get, isArray, isNull, isUndefined, isEqual, map, isEmpty, isFunction, assign, isBoolean, isObject } from 'lodash-es'
import { useMaintain, type MaintainAjaxConfig } from '@enocloud/hooks'
import { EnSelect, EnTag, EnOption } from '@components/index'

interface NormalizedOption {
  label: string
  value: string | number | Record<string, any>
  disabled?: boolean
  data: any
}

interface SelectProps {
  label: string | ((option?: any) => string | number)
  value: string | ((option?: any) => any)
  disabled: ((option: any) => boolean | undefined) | null
}

interface Props {
  ajax?: MaintainAjaxConfig
  allowCreate?: boolean
  clearable?: boolean
  collapseTags?: boolean
  collapseTagsPanel?: boolean
  collapseTagsTooltip?: boolean
  disabled?: boolean
  defaultFirstOption?: boolean
  filterable?: boolean
  lazy?: boolean
  loading?: boolean
  modelValue?: any
  multiple?: boolean
  options?: unknown[]
  placeholder?: string
  props?: Partial<SelectProps>
  remote?: boolean
  teleported?: boolean
  valueKey?: string
  debug?: boolean
}

interface Emits {
  (e: 'update:model-value', value: any): void
  (e: 'change', value: any): void
  (e: 'focus'): void
  (e: 'blur'): void
}

const props = withDefaults(defineProps<Props>(), {
  defaultFirstOption: true,
  placeholder: '请选择',
  props: () => ({
    label: 'label',
    value: 'value'
  }),
  teleported: true,
  clearable: true
})
const emits = defineEmits<Emits>()

const selectRef = ref<InstanceType<typeof EnSelect> | null>(null)

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    emits('change', value)
  }
})

const { data, loading, run } = useMaintain('select', {
  ajax: props.ajax,
  lazy: props.lazy,
  data: toRef(props, 'options')
})

const normalizedProps = computed<SelectProps>(() => assign({ label: 'label', value: 'value', disabled: null }, props.props))

const getLabel = (option: any) => {
  const { label } = normalizedProps.value
  return isFunction(label) ? `${label(option)}` : `${get(option, label)}`
}
const getValue = (option: any) => {
  const { value } = normalizedProps.value
  return isFunction(value) ? value(option) : value === '' ? option : get(option, value)
}

const normalized = (item: any): NormalizedOption => {
  const label = getLabel(item)
  const value = getValue(item)
  const disabled = isFunction(normalizedProps.value.disabled)
    ? normalizedProps.value.disabled(item)
    : isBoolean(normalizedProps.value.disabled)
    ? normalizedProps.value.disabled
    : false

  return { label, value, disabled, data: item }
}

const pendingData = ref<any[]>([])
const normalizedData = computed<NormalizedOption[]>(() => concat(map(data.value, normalized), map(pendingData.value, normalized)))

const isEqualWithModelValue = (option: NormalizedOption) => {
  if (isObject(option.value)) {
    if (isFunction(normalizedProps.value.value)) {
      return false
    } else {
      if (!props.valueKey) throw new Error('When the value is an object, please specify the value-key prop.')
      return isObject(props.modelValue) && isEqual(get(option.value, props.valueKey), get(props.modelValue, props.valueKey))
    }
  }
}

const handlePendingData = () => {
  pendingData.value = []
  if (isNull(props.modelValue) || isUndefined(props.modelValue) || isEmpty(props.modelValue)) return
  if (props.remote) {
    if (props.multiple) {
      if (isArray(props.modelValue)) {
      }
    } else {
      if (isArray(props.modelValue)) return
      const exist = normalizedData.value.find(isEqualWithModelValue)
      if (!exist) pendingData.value = [props.modelValue]
    }
  }
}

watch([() => props.modelValue, data], handlePendingData)

let isRunWithValue = false
const remoteMethod = (value: string) => {
  if (value) {
    isRunWithValue = true
    run?.(value)
  } else {
    if (isRunWithValue) run?.()
    isRunWithValue = false
  }
}

const onCollapseTagsClose = (tag: unknown) => {
  if (isArray(_modelValue.value)) {
    _modelValue.value.splice(
      _modelValue.value.findIndex((item) => isEqual(item, tag)),
      1
    )
  }
}

const focused = ref(false)
const onSelectInputFocus = () => {
  if (props.lazy && !focused.value) {
    focused.value = true
    run?.()
  }
  emits('focus')
}

const onSelectInputBlur = () => {
  emits('blur')
}

defineExpose({ ajax: run, focus: () => selectRef.value?.focus() })
</script>
