<template>
  <el-input-number v-model="_modelValue" :controls="false" :min="0" :max="100" :precision="precision ?? 2"></el-input-number>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  modelValue?: number
  precision?: number
}

interface Emits {
  (e: 'update:model-value', value?: number): void
  (e: 'change', value: number): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => (props.modelValue ?? 0) * 100,
  set: (value) => {
    emits('update:model-value', value / 100 ?? 0)
    emits('change', value / 100 ?? 0)
  }
})
</script>

<style>
.el-input-number.w-full {
  width: 100% !important;
}
</style>
