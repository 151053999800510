<template>
  <el-tabs :model-value="modelValue" @tab-change="onTabChange">
    <el-tab-pane
      v-if="normalizedConfig.order === 'after'"
      :disabled="disabled"
      :label="normalizedConfig.label"
      :name="normalizedConfig.name"
    ></el-tab-pane>
    <el-tab-pane v-for="item of normalizedData" :disabled="disabled" :label="item.label" :name="item.name"></el-tab-pane>
    <el-tab-pane
      v-if="normalizedConfig.order === 'before'"
      :disabled="disabled"
      :label="normalizedConfig.label"
      :name="normalizedConfig.name"
    ></el-tab-pane>
  </el-tabs>
</template>

<script setup lang="ts">
import { computed, toRef, watchEffect } from 'vue'
import { useMaintain } from '@enocloud/hooks'
import type { AjaxConfig } from '@enocloud/utils'
import { assign, get, map } from 'lodash-es'

type ModelValue = string | number

interface TabsProps {
  label: string
  value: string
}

interface TabsConfig {
  name?: string
  label?: string
  order?: 'after' | 'before' | 'none'
}

interface NormalizedItem {
  label: string
  name: string
  disabled: boolean
}

interface Props {
  ajax?: AjaxConfig
  class?: any
  clearable?: boolean
  config?: TabsConfig
  filterable?: boolean
  method?: Function
  modelValue?: ModelValue
  placeholder?: string
  props?: Partial<TabsProps>
  remote?: boolean
  valueKey?: string
  options?: unknown[]
  defaultFirstOption?: boolean
  disabled?: boolean
}

interface Emits {
  (e: 'update:modelValue', value: ModelValue): void
  (e: 'change', value: ModelValue): void
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: ''
})
const emits = defineEmits<Emits>()

const onTabChange = (name: ModelValue) => {
  emits('update:modelValue', name)
  emits('change', name)
  props.method?.()
}

const normalizedConfig = computed(() => assign({ name: '', label: '全部', order: 'after' }, props.config))
const normalizedProps = computed<TabsProps>(() => assign({ label: 'label', value: 'value' }, props.props))

const { data, loading, run } = useMaintain('tabs', {
  ajax: props.ajax,
  data: toRef(props, 'options')
})

const normalized = (item: any): NormalizedItem => {
  const label = get(item, normalizedProps.value.label)
  const name = get(item, normalizedProps.value.value)
  return { label, name, disabled: false }
}

const normalizedData = computed<NormalizedItem[]>(() => map(data.value, normalized))

let setFirstOptionExcute = true
watchEffect(() => {
  if (props.defaultFirstOption && normalizedData.value.length && setFirstOptionExcute && !props.modelValue) {
    emits('update:modelValue', normalizedData.value[0].name)
    setFirstOptionExcute = false
  }
})
</script>
