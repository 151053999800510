<template>
  <div class="flex-1 overflow-auto" ref="el">
    <slot :height="rect.height"></slot>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useResizeObserver } from '@enocloud/hooks'

interface Props {
  flexClass?: string
}

const props = defineProps<Props>()

const el = ref<HTMLElement | null>()

const { rect } = useResizeObserver(el)

const addFlexClass = (el?: HTMLElement | null) => {
  if (el) {
    let classes = el.className.split(' ')
    classes = classes.concat(['flex-box-parent'])
    classes = [...new Set(classes)]
    el.className = classes.join(' ')
  }
}

const setup = () => {
  addFlexClass(el.value?.parentElement)
}

onMounted(setup)
</script>

<style>
.flex-box-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.h-full {
  height: 100%;
}
</style>
