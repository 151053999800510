<template>
  <el-tooltip
    ref="tooltipRef"
    :visible="popperVisible"
    :teleported="teleported"
    :popper-class="[nsCascader.e('dropdown'), popperClass]"
    :popper-options="popperOptions"
    :fallback-placements="['bottom-start', 'bottom', 'top-start', 'top', 'right', 'left']"
    :stop-popper-mouse-event="false"
    placement="bottom-start"
    :transition="`${nsCascader.namespace.value}-zoom-in-top`"
    effect="light"
    pure
    persistent
    :gpu-acceleration="false"
    @hide="hideSuggestionPanel"
  >
    <div
      v-clickoutside:[contentRef]="() => togglePopperVisible(false)"
      :class="cascaderKls"
      :style="cascaderStyle"
      @click="() => togglePopperVisible(readonly ? false : true)"
      @keydown="handleKeyDown"
      @mouseenter="inputHover = true"
      @mouseleave="inputHover = false"
    >
      <el-input
        v-model="inputValue"
        ref="inputRef"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        :validate-event="false"
        :size="realSize"
        :tabindex="multiple && filterable && !isDisabled ? -1 : undefined"
        @compositionstart="handleComposition"
        @compositionupdate="handleComposition"
        @compositionend="handleComposition"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
      >
        <template #suffix>
          <el-icon v-if="clearBtnVisible" key="clear" :class="[nsInput.e('icon'), 'icon-circle-close']" @click.stop="handleClear">
            <circle-close></circle-close>
          </el-icon>
          <el-icon v-else key="arrow-down" :class="cascaderIconKls" @click.stop="togglePopperVisible()">
            <arrow-down />
          </el-icon>
        </template>
      </el-input>

      <div v-if="multiple" ref="tagWrapper" :class="nsCascader.e('tags')">
        <el-tag
          v-for="tag in presentTags"
          :key="tag.key"
          :type="tagType"
          :size="tagSize"
          :hit="tag.hitState"
          :closable="tag.closable"
          disable-transitions
          @close="deleteTag(tag)"
        >
          <template v-if="tag.isCollapseTag === false">
            <span>{{ tag.text }}</span>
          </template>
          <template v-else>
            <el-tooltip
              :disabled="popperVisible || !collapseTagsTooltip"
              :fallback-placements="['bottom', 'top', 'right', 'left']"
              placement="bottom"
              effect="light"
            >
              <template #default>
                <span>{{ tag.text }}</span>
              </template>
              <template #content>
                <div :class="nsCascader.e('collapse-tags')">
                  <div v-for="(tag2, idx) in allPresentTags.slice(1)" :key="idx" :class="nsCascader.e('collapse-tag')">
                    <el-tag
                      :key="tag2.key"
                      class="in-tooltip"
                      :type="tagType"
                      :size="tagSize"
                      :hit="tag2.hitState"
                      :closable="tag2.closable"
                      disable-transitions
                      @close="deleteTag(tag2)"
                    >
                      <span>{{ tag2.text }}</span>
                    </el-tag>
                  </div>
                </div>
              </template>
            </el-tooltip>
          </template>
        </el-tag>
        <input
          v-if="filterable && !isDisabled"
          v-model="searchInputValue"
          text
          :class="nsCascader.e('search-input')"
          :placeholder="presentText ? '' : inputPlaceholder"
          @input="(e) => handleInput(searchInputValue, e as KeyboardEvent)"
          @click.stop="togglePopperVisible(true)"
          @keydown.delete="handleDelete"
          @compositionstart="handleComposition"
          @compositionupdate="handleComposition"
          @compositionend="handleComposition"
          @focus="handleFocus"
          @blur="handleBlur"
        />
      </div>
    </div>

    <template #content>
      <el-cascader-panel
        v-show="!filtering"
        ref="cascaderPanelRef"
        v-model="checkedValue"
        :options="options"
        :props="props.props"
        :border="false"
        :render-label="$slots.default"
        @expand-change="handleExpandChange"
        @close="$nextTick(() => togglePopperVisible(false))"
      ></el-cascader-panel>

      <el-scrollbar
        v-if="filterable"
        v-show="filtering"
        ref="suggestionPanel"
        tag="ul"
        :class="nsCascader.e('suggestion-panel')"
        :view-class="nsCascader.e('suggestion-list')"
        @keydown="handleSuggestionKeyDown"
      >
        <template v-if="suggestions.length">
          <li
            v-for="item in suggestions"
            :key="item.uid"
            :class="[nsCascader.e('suggestion-item'), nsCascader.is('checked', item.checked)]"
            :tabindex="-1"
            @click="handleSuggestionClick(item)"
          >
            <span>{{ item.text }}</span>
            <el-icon v-if="item.checked">
              <check />
            </el-icon>
          </li>
        </template>
        <slot v-else name="empty">
          <li :class="nsCascader.e('empty-text')">
            {{ t('el.cascader.noMatch') }}
          </li>
        </slot>
      </el-scrollbar>
    </template>
  </el-tooltip>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, useAttrs, nextTick, watch, type StyleValue } from 'vue'
import { cloneDeep, debounce } from 'lodash-es'
import { useCssVar, useResizeObserver, isClient } from '@vueuse/core'
import {
  ClickOutside as vClickoutside,
  ElInput,
  ElTooltip,
  ElTag,
  ElCascaderPanel,
  ElIcon,
  useLocale,
  useFormItem,
  useFormSize,
  useNamespace,
  EVENT_CODE,
  type ScrollbarInstance,
  type TooltipInstance,
  type CascaderPanelInstance,
  type InputInstance,
  type CascaderValue,
  type CascaderProps,
  type CascaderOption,
  type CascaderNode,
  type Options,
  type Tag
} from 'element-plus'
import { ArrowDown, CircleClose, Check } from '@element-plus/icons-vue'

interface Props {
  allowCreate?: boolean
  beforeFilter?: (value: string) => boolean | Promise<any>
  clearable?: boolean
  collapseTags?: boolean
  collapseTagsTooltip?: boolean
  disabled?: boolean
  debounce?: number
  filterMethod?: (node: CascaderNode, keyword: string) => boolean
  filterable?: boolean
  modelValue?: CascaderValue
  options?: CascaderOption[]
  placeholder?: string
  props?: CascaderProps
  popperClass?: string
  separator?: string
  showAllLevels?: boolean
  tagType?: 'success' | 'info' | 'warning' | 'danger' | ''
  teleported?: boolean
  validateEvent?: boolean
}

interface Emits {
  (e: 'update:model-value', value?: CascaderValue): void
  (e: 'change', value?: CascaderValue): void
  (e: 'focus', value: FocusEvent): void
  (e: 'blur', value: FocusEvent): void
  (e: 'visible-change', value: boolean): void
  (e: 'expand-change', value: CascaderValue): void
  (e: 'remove-tag', value: CascaderNode['valueByOption']): void
  (e: 'dynamic-create', value: string): void
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: '请选择',
  teleported: true,
  popperClass: '',
  props: () => ({}),
  debounce: 300,
  separator: '/',
  showAllLevels: true,
  tagType: 'info',
  filterMethod: (node: CascaderNode, keyword: string) => node.text.includes(keyword),
  beforeFilter: (value: string) => true,
  validateEvent: true
})
const emits = defineEmits<Emits>()

const popperOptions: Partial<Options> = {
  modifiers: [
    {
      name: 'arrowPosition',
      enabled: true,
      phase: 'main',
      fn: ({ state }) => {
        const { modifiersData, placement } = state as any
        if (['right', 'left', 'bottom', 'top'].includes(placement)) return
        modifiersData.arrow.x = 35
      },
      requires: ['arrow']
    }
  ]
}

let inputInitialHeight = 0
let pressDeleteCount = 0

const nsCascader = useNamespace('cascader')
const nsInput = useNamespace('input')
const { form, formItem } = useFormItem()
const realSize = useFormSize()
const attrs = useAttrs()
const { t } = useLocale()

const tooltipRef = ref<TooltipInstance | null>(null)
const inputRef = ref<InputInstance | null>()
const cascaderPanelRef = ref<CascaderPanelInstance | null>(null)
const suggestionPanel = ref<ScrollbarInstance | null>(null)
const inputValue = ref('')
const popperVisible = ref(false)
const filtering = ref(false)
const filterFocus = ref(false)
const inputHover = ref(false)
const isOnComposition = ref(false)
const searchInputValue = ref('')
const presentTags = ref<Tag[]>([])
const allPresentTags = ref<Tag[]>([])
const suggestions = ref<CascaderNode[]>([])
const tagWrapper = ref(null)

const contentRef = computed(() => tooltipRef.value?.popperRef?.contentRef)
const multiple = computed(() => !!props.props.multiple)
const readonly = computed(() => !props.filterable || multiple.value)
const isDisabled = computed(() => props.disabled || form?.disabled)
const inputPlaceholder = computed(() => props.placeholder || t('el.cascader.placeholder'))
const checkedNodes = computed<CascaderNode[]>(() => cascaderPanelRef.value?.checkedNodes || [])
const clearBtnVisible = computed(() => {
  if (!props.clearable || isDisabled.value || filtering.value || !inputHover.value) return false
  return !!checkedNodes.value.length
})
const cascaderIconKls = computed(() => {
  return [nsInput.e('icon'), 'icon-arrow-down', nsCascader.is('reverse', popperVisible.value)]
})
const searchKeyword = computed(() => (multiple.value ? searchInputValue.value : inputValue.value))

const checkedValue = computed<CascaderValue>({
  get() {
    return cloneDeep(props.modelValue) as CascaderValue
  },
  set(value) {
    emits('update:model-value', value)
    emits('change', value)

    if (props.validateEvent) {
      formItem?.validate('change').catch((err) => console.error(err))
    }
  }
})
const presentText = computed(() => {
  const { showAllLevels, separator } = props
  const nodes = checkedNodes.value
  return nodes.length ? (multiple.value ? '' : nodes[0].calcText(showAllLevels, separator)) : ''
})
const tagSize = computed(() => (['small'].includes(realSize.value) ? 'small' : 'default'))

const cascaderKls = computed(() => [nsCascader.b(), nsCascader.m(realSize.value), nsCascader.is('disabled', isDisabled.value), attrs.class])
const cascaderStyle = computed<StyleValue>(() => attrs.style as StyleValue)

const handleFocus = (e: FocusEvent) => {
  const el = e.target as HTMLInputElement
  const name = nsCascader.e('search-input')
  if (el.className === name) {
    filterFocus.value = true
  }
  emits('focus', e)
}

const handleBlur = (e: FocusEvent) => {
  filterFocus.value = false
  emits('blur', e)
}

const handleClear = () => {
  cascaderPanelRef.value?.clearCheckedNodes()
  if (!popperVisible.value && props.filterable) {
    syncPresentTextValue()
  }
  togglePopperVisible(false)
}

const syncPresentTextValue = () => {
  const { value } = presentText
  inputValue.value = value
  searchInputValue.value = value
}

const togglePopperVisible = (visible?: boolean) => {
  if (isDisabled.value) return

  visible = visible ?? !popperVisible.value

  if (visible !== popperVisible.value) {
    popperVisible.value = visible
    inputRef.value?.input?.setAttribute('aria-expanded', `${visible}`)

    if (visible) {
      updatePopperPosition()
      nextTick(cascaderPanelRef.value?.scrollToExpandingNode)
    } else if (props.filterable) {
      syncPresentTextValue()
    }

    emits('visible-change', visible)
  }
}

const hideSuggestionPanel = () => {
  filtering.value = false
}

const calculateSuggestions = () => {
  const { filterMethod, showAllLevels, separator } = props
  const res = cascaderPanelRef.value?.getFlattedNodes(!props.props.checkStrictly)?.filter((node) => {
    if (node.isDisabled) return false
    node.calcText(showAllLevels, separator)
    return filterMethod(node, searchKeyword.value)
  })
  if (multiple.value) {
    presentTags.value.forEach((tag) => {
      tag.hitState = false
    })
    allPresentTags.value.forEach((tag) => {
      tag.hitState = false
    })
  }

  filtering.value = true
  suggestions.value = res!
  updatePopperPosition()
}

const updatePopperPosition = () => {
  nextTick(() => {
    tooltipRef.value?.updatePopper()
  })
}

const isFunction = (val: unknown): val is Function => typeof val === 'function'
const isObject = (val: unknown): val is Record<any, any> => val !== null && typeof val === 'object'
const isPromise = <T = any,>(val: unknown): val is Promise<T> => {
  return isObject(val) && isFunction(val.then) && isFunction(val.catch)
}

const handleFilter = debounce(() => {
  const { value } = searchKeyword

  if (!value) return

  const passed = props.beforeFilter(value)

  if (isPromise(passed)) {
    passed.then(calculateSuggestions).catch(() => {
      /* prevent log error */
    })
  } else if (passed !== false) {
    calculateSuggestions()
  } else {
    hideSuggestionPanel()
  }
}, props.debounce)

const isKorean = (text: string) => /([\uAC00-\uD7AF\u3130-\u318F])+/gi.test(text)
const handleComposition = (event: CompositionEvent) => {
  const text = (event.target as HTMLInputElement)?.value
  if (event.type === 'compositionend') {
    isOnComposition.value = false
    nextTick(() => handleInput(text))
  } else {
    const lastCharacter = text[text.length - 1] || ''
    isOnComposition.value = !isKorean(lastCharacter)
  }
}

const focusFirstNode = () => {
  let firstNode!: HTMLElement

  if (filtering.value && suggestionPanel.value) {
    firstNode = suggestionPanel.value.$el.querySelector(`.${nsCascader.e('suggestion-item')}`)
  } else {
    firstNode = cascaderPanelRef.value?.$el.querySelector(`.${nsCascader.b('node')}[tabindex="-1"]`)
  }

  if (firstNode) {
    firstNode.focus()
    !filtering.value && firstNode.click()
  }
}

const handleExpandChange = (value: CascaderValue) => {
  updatePopperPosition()
  emits('expand-change', value)
}

const handleKeyDown = (e: KeyboardEvent) => {
  if (isOnComposition.value) return

  switch (e.code) {
    case EVENT_CODE.enter:
      togglePopperVisible()
      break
    case EVENT_CODE.down:
      togglePopperVisible(true)
      nextTick(focusFirstNode)
      e.preventDefault()
      break
    case EVENT_CODE.esc:
      if (popperVisible.value === true) {
        e.preventDefault()
        e.stopPropagation()
        togglePopperVisible(false)
      }
      break
    case EVENT_CODE.tab:
      togglePopperVisible(false)
      break
  }
}

const isLeaf = (el: HTMLElement) => !el.getAttribute('aria-owns')
const focusNode = (el: HTMLElement) => {
  if (!el) return
  el.focus()
  !isLeaf(el) && el.click()
}
const getSibling = (el: HTMLElement, distance: number, elClass: string) => {
  const { parentNode } = el
  if (!parentNode) return null
  const siblings = parentNode.querySelectorAll(elClass)
  const index = Array.prototype.indexOf.call(siblings, el)
  return siblings[index + distance] || null
}

const handleSuggestionClick = (node: CascaderNode) => {
  const { checked } = node

  if (multiple.value) {
    cascaderPanelRef.value?.handleCheckChange(node, !checked, false)
  } else {
    !checked && cascaderPanelRef.value?.handleCheckChange(node, true, false)
    togglePopperVisible(false)
  }
}
const handleSuggestionKeyDown = (e: KeyboardEvent) => {
  const target = e.target as HTMLElement
  const { code } = e

  switch (code) {
    case EVENT_CODE.up:
    case EVENT_CODE.down: {
      const distance = code === EVENT_CODE.up ? -1 : 1
      focusNode(getSibling(target, distance, `.${nsCascader.e('suggestion-item')}[tabindex="-1"]`) as HTMLElement)
      break
    }
    case EVENT_CODE.enter:
      target.click()
      break
  }
}

const handleInput = (val: string, e?: KeyboardEvent) => {
  !popperVisible.value && togglePopperVisible(true)
  if (e?.isComposing) return
  val ? handleFilter() : hideSuggestionPanel()
}

const genTag = (node: CascaderNode): Tag => {
  const { showAllLevels, separator } = props
  return {
    node,
    key: node.uid,
    text: node.calcText(showAllLevels, separator),
    hitState: false,
    closable: !isDisabled.value && !node.isDisabled,
    isCollapseTag: false
  }
}

const deleteTag = (tag: Tag) => {
  const node = tag.node as CascaderNode
  node.doCheck(false)
  cascaderPanelRef.value?.calculateCheckedValue()
  emits('remove-tag', node.valueByOption)
}

const handleDelete = () => {
  const tags = presentTags.value
  const lastTag = tags[tags.length - 1]
  pressDeleteCount = searchInputValue.value ? 0 : pressDeleteCount + 1

  if (!lastTag || !pressDeleteCount || (props.collapseTags && tags.length > 1)) return

  if (lastTag.hitState) {
    deleteTag(lastTag)
  } else {
    lastTag.hitState = true
  }
}

const updateStyle = () => {
  const inputInner = inputRef.value?.input
  const tagWrapperEl = tagWrapper.value
  const suggestionPanelEl = suggestionPanel.value?.$el

  if (!isClient || !inputInner) return

  if (suggestionPanelEl) {
    const suggestionList = suggestionPanelEl.querySelector(`.${nsCascader.e('suggestion-list')}`)
    suggestionList.style.minWidth = `${inputInner.offsetWidth}px`
  }

  if (tagWrapperEl) {
    const { offsetHeight } = tagWrapperEl
    const height = presentTags.value.length > 0 ? `${Math.max(offsetHeight + 6, inputInitialHeight)}px` : `${inputInitialHeight}px`
    inputInner.style.height = height
    updatePopperPosition()
  }
}

const calculatePresentTags = () => {
  if (!multiple.value) return

  const nodes = checkedNodes.value
  const tags: Tag[] = []

  const allTags: Tag[] = []
  nodes.forEach((node) => allTags.push(genTag(node)))
  allPresentTags.value = allTags

  if (nodes.length) {
    const [first, ...rest] = nodes
    const restCount = rest.length

    tags.push(genTag(first))

    if (restCount) {
      if (props.collapseTags) {
        tags.push({
          key: -1,
          text: `+ ${restCount}`,
          closable: false,
          isCollapseTag: true
        })
      } else {
        rest.forEach((node) => tags.push(genTag(node)))
      }
    }
  }

  presentTags.value = tags
}

watch(filtering, updatePopperPosition)

watch([checkedNodes, isDisabled], calculatePresentTags)

watch(presentTags, () => {
  nextTick(() => updateStyle())
})

watch(presentText, syncPresentTextValue, { immediate: true })

watch(inputValue, (value: string) => {
  if (props.allowCreate) {
    emits('dynamic-create', value)
  }
})

onMounted(() => {
  const inputInner = inputRef.value!.input!
  const inputInnerHeight = Number.parseFloat(useCssVar(nsInput.cssVarName('input-height'), inputInner).value) - 2
  inputInitialHeight = inputInner.offsetHeight || inputInnerHeight
  useResizeObserver(inputInner, updateStyle)
})
</script>
