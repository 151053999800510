<template>
  <el-input-number
    v-model="_modelValue"
    :controls="controls"
    :controls-position="controlsPosition"
    :precision="precision"
    :min="min"
    :max="max"
    :step-strictly="stepStrictly"
    :step="step"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
  ></el-input-number>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  controls?: boolean
  controlsPosition?: '' | 'right'
  max?: number
  min?: number
  step?: number
  modelValue?: number
  precision?: number
  stepStrictly?: boolean
}

interface Emits {
  (e: 'update:model-value', value?: number): void
  (e: 'change', value: number): void
  (e: 'blur', value: Event): void
  (e: 'focus', value: Event): void
}

const props = withDefaults(defineProps<Props>(), { controls: false })
const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue ?? 0,
  set: (value) => {
    emits('update:model-value', value ?? 0)
    emits('change', value ?? 0)
  }
})
</script>

<style>
.el-input-number.w-full {
  width: 100% !important;
}
</style>
