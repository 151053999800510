<template>
  <en-card class="h-full" body-class="h-full gap-6">
    <div class="flex items-center justify-between">
      <en-dropdown v-if="routes && routes.length && !store.collapse" trigger="click" @command="onRouteCommand">
        <span class="cursor-pointer flex items-center gap-2">
          {{ currentRoute?.name }}
          <en-icon><IArrowDown /></en-icon>
        </span>
        <template #dropdown>
          <en-dropdown-item v-for="item of routes" :command="item.path">{{ item.name }}</en-dropdown-item>
        </template>
      </en-dropdown>
      <en-button v-if="!store.collapse" :type="dateActive === 'day' ? 'primary' : ''" link @click="onDateChange('day')">今</en-button>
      <en-button v-if="!store.collapse" :type="dateActive === 'week' ? 'primary' : ''" link @click="onDateChange('week')">周</en-button>
      <en-button v-if="!store.collapse" :type="dateActive === 'month' ? 'primary' : ''" link @click="onDateChange('month')">月</en-button>
      <img src="@components/assets/icon-calender.png" width="16" ref="calenderImgRef" class="cursor-pointer" />
      <img v-if="!store.collapse" src="@components/assets/icon-search.png" width="16" class="cursor-pointer" @click="onSearchClick" />
      <img src="@components/assets/icon-refresh.png" width="16" class="cursor-pointer" @click="onRefreshClick" />
      <img v-if="!store.collapse" src="@components/assets/icon-arrow-l.png" width="24" class="cursor-pointer" @click="store.changeCollapse" />
      <img v-if="store.collapse" src="@components/assets/icon-arrow-r.png" width="24" class="cursor-pointer" @click="store.changeCollapse" />
      <img v-if="!store.collapse" src="@components/assets/icon-expand.png" width="24" class="cursor-pointer" @click="onExpandClick" />
    </div>

    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          v-if="mainTable.code"
          :height="height"
          :code="mainTable.code"
          :data="mainTable.data"
          :loading="mainTable.loading"
          :paging="mainTable.paging"
          :method="mainTable.method"
          showItemCount
          @row-click="$emit('row-click', $event)"
          :config="tableConfig"
        >
          <template v-for="item of mainTable.slots" v-slot:[`${item.name}`]="{ row, $index }">
            <slot :name="item.name" :row="row" :$index="$index"></slot>
          </template>
        </table-dynamic>

        <en-table
          v-else
          :height="height"
          :data="mainTable.data"
          :loading="mainTable.loading"
          :paging="mainTable.paging"
          :method="mainTable.method"
          showItemCount
          @row-click="$emit('row-click', $event)"
        >
          <slot></slot>
        </en-table>
      </template>
    </flex-box>
  </en-card>

  <el-popover ref="popoverDateRef" :virtual-ref="calenderImgRef" trigger="click" virtual-triggering pure width="auto">
    <en-date-picker :start="startDate" :end="endDate" type="daterange" @change="onDateChange"></en-date-picker>
  </el-popover>

  <en-drawer v-model="searchVisible" direction="ltr" :title="`${name ?? ''}搜索`">
    <en-form>
      <slot name="form" :data="addition"></slot>
    </en-form>
    <template #footer>
      <en-button @click="onSearchCancelClick">取消</en-button>
      <en-button type="primary" @click="onSearchResetClick">重置</en-button>
      <en-button type="primary" @click="onSearchSubmitClick">确定</en-button>
    </template>
  </en-drawer>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, useSlots, watchEffect } from 'vue'
import dayjs, { Dayjs } from 'dayjs'
import { get, set, find, pickBy } from 'lodash-es'
import { ArrowDown as IArrowDown } from '@element-plus/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import { ajax, useStore, type TableConfig } from '@enocloud/utils'
import {
  EnButton,
  EnCard,
  EnDropdown,
  EnDropdownItem,
  EnDatePicker,
  EnDrawer,
  EnForm,
  EnIcon,
  FlexBox,
  TableDynamic,
  EnTable
} from '@components/index'

import type { MaintainAjaxConfig } from '@enocloud/hooks'
import type { PopoverInstance } from 'element-plus'

interface Route {
  path: string
  name: string
}

interface NormalizeProps {
  start?: string
  end?: string
  format?: string
}

interface Props {
  ajax: MaintainAjaxConfig
  code?: string
  start?: string
  end?: string
  name?: string
  routes?: Route[]
  props?: NormalizeProps
  addition?: Record<string, any>
  tableConfig?: TableConfig
}

interface Emits {
  (e: 'row-click', value: unknown): void
  (e: 'expand-click'): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const store = useStore()
const router = useRouter()
const route = useRoute()

const currentRoute = computed(() => find(props.routes, ['path', route.path]))
const onRouteCommand = (command: string) => router.push(command)

const addition = ref<Record<string, any>>({})

watchEffect(() => {
  if (props.addition) {
    Object.assign(addition.value, props.addition)
  }
})
const startDate = computed(() => (props.props?.start ? get(addition.value, props.props.start, '') : ''))
const endDate = computed(() => (props.props?.end ? get(addition.value, props.props.end, '') : ''))

const useManifestTable = (
  code: () => string | undefined,
  ajaxConfig: MaintainAjaxConfig,
  pickSlotBy: (slot: Function, name: string) => boolean,
  addition: () => Record<string, any>
) => {
  const slots = useSlots()

  const tableCode = computed(code)

  const _addition = computed(addition)

  const data = ref([])
  const loading = ref(false)
  const paging = ref({ pageIndex: 1, pageCount: 0, itemCount: 0, pageSize: 20 })

  const method = async ({
    addition,
    invokedByPagination,
    invokedByScroll
  }: {
    addition?: Record<string, any>
    invokedByPagination?: boolean
    invokedByScroll?: boolean
  } = {}) => {
    if (!ajaxConfig) return
    const { action, params } = ajaxConfig

    const ajaxParams: any = { payload: {}, paths: [] }
    params?.(ajaxParams)

    try {
      loading.value = true

      const res: any = await ajax(action, {
        paths: ajaxParams.paths,
        payload: Object.assign(ajaxParams.payload, addition, _addition.value, invokedByPagination ? paging.value : null)
      })
      data.value = invokedByScroll ? [...data.value, ...res.data] : res.data
      paging.value = res.meta.paging
    } catch (err) {
    } finally {
      loading.value = false
    }
  }

  onMounted(() => {
    method()
  })

  const refresh = () => {
    method()
  }

  return reactive({
    code: tableCode,
    data,
    loading,
    method,
    paging,
    refresh,
    slots: Object.entries(pickBy(slots, pickSlotBy)).map(([name, slot]) => ({ name, slot }))
  })
}

const mainTable = useManifestTable(
  () => props.code,
  props.ajax,
  (_, name) => name !== 'form' && !name.endsWith('_FILTER'),
  () => addition.value
)

const dateActive = ref('')

const onDateChange = (date: 'day' | 'week' | 'month' | string[]) => {
  if (!props.props?.start || !props.props?.end) return

  if (dateActive.value === date) {
    dateActive.value = ''
    date = []
  }

  dateActive.value = typeof date === 'string' && ['day', 'week', 'month'].includes(date) ? date : ''

  let start: Dayjs | null
  let end: Dayjs | null
  let format = props.props?.format ?? 'YYYY-MM-DD'

  if (date === 'day') {
    start = dayjs()
    end = dayjs()
  } else if (date === 'week') {
    start = dayjs().subtract(7, 'd')
    end = dayjs()
  } else if (date === 'month') {
    start = dayjs().subtract(1, 'M')
    end = dayjs()
  } else {
    start = date[0] ? dayjs(date[0]) : null
    end = date[1] ? dayjs(date[1]) : null
  }

  set(addition.value, props.props.start, start ? start.format(format) : '')
  set(addition.value, props.props.end, end ? end.format(format) : '')

  mainTable.method?.()
}

const searchVisible = ref(false)
const onSearchClick = () => {
  searchVisible.value = true
}

const onRefreshClick = () => {
  dateActive.value = ''
  addition.value = {}
  mainTable.method?.()
}

const calenderImgRef = ref<HTMLImageElement | null>()
const popoverDateRef = ref<PopoverInstance | null>()

const onSearchCancelClick = () => (searchVisible.value = false)
const onSearchResetClick = () => {
  addition.value = {}
  mainTable.method?.()
  searchVisible.value = false
}
const onSearchSubmitClick = () => {
  mainTable.method?.()
  searchVisible.value = false
}

const onExpandClick = () => emits('expand-click')

defineExpose({ table: mainTable })
</script>
