<template>
  <el-date-picker
    v-model="_modelValue"
    ref="datePickerRef"
    :disabled="disabled"
    :type="type"
    :placeholder="placeholder"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :value-format="_valueformat"
    :teleported="_teleported"
    :clearable="clearable"
  ></el-date-picker>
</template>

<script setup lang="ts">
import { computed, inject, unref, ref } from 'vue'
import { EnFormKey } from '@components/shared'

import type { DateModelType, DatePickType, ElDatePicker } from 'element-plus'

interface Props {
  disabled?: boolean
  modelValue?: string | string[] | number
  start?: string
  end?: string
  placeholder?: string
  clearable?: boolean
  startPlaceholder?: string
  endPlaceholder?: string
  type?: DatePickType
  teleported?: boolean
  valueFormat?: string
}

interface Emits {
  (e: 'clear'): void
  (e: 'change', value: any): void
  (e: 'update:start', value: DateModelType): void
  (e: 'update:end', value: DateModelType): void
  (e: 'change:start', value: DateModelType): void
  (e: 'change:end', value: DateModelType): void
  (e: 'update:modelValue', value: DateModelType): void
}

const props = withDefaults(defineProps<Props>(), {
  type: 'date',
  start: '',
  end: '',
  placeholder: '请选择',
  startPlaceholder: '请选择',
  endPlaceholder: '请选择',
  clearable: true
})
const emits = defineEmits<Emits>()

const datePickerRef = ref<InstanceType<typeof ElDatePicker> | null>(null)

const _modelValue = computed<any>({
  get() {
    return ~props.type.search('range') ? [props.start ?? '', props.end ?? ''] : props.modelValue!
  },
  set(value) {
    if (~props.type.search('range')) {
      const [start, end] = value ?? []
      emits('update:start', start)
      emits('change:start', start)
      emits('update:end', end)
      emits('change:end', end)
      emits('change', value ?? [])
    } else {
      emits('update:modelValue', value)
      emits('change', value)
    }
  }
})

const enFormContext = inject(EnFormKey, undefined)
const _teleported = computed(() => unref(enFormContext?.teleported) || props.teleported)

const _valueformat = computed(() =>
  props.valueFormat ? props.valueFormat : props.type.includes('month') ? 'YYYY' : props.type.includes('time') ? 'YYYY-MM-DD hh:mm:ss' : 'YYYY-MM-DD'
)

defineExpose({
  focus: () => (datePickerRef.value as any)?.focus()
})
</script>

<style>
.el-date-editor.w-full {
  --el-date-editor-width: 100%;
  display: inline-flex;
  box-sizing: border-box;
  vertical-align: center;
}
</style>
