<template>
  <div v-loading="loading" ref="echartRef"></div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeMount, watchEffect, type WatchStopHandle } from 'vue'
import { init as EchartInit, use, type EChartsType, type ComposeOption } from 'echarts/core'
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  type DatasetComponentOption,
  type LegendComponentOption
} from 'echarts/components'
import {
  BarChart,
  LineChart,
  PieChart,
  ScatterChart,
  type BarSeriesOption,
  type PieSeriesOption,
  type LineSeriesOption,
  type ScatterSeriesOption
} from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

type ECOption = ComposeOption<
  DatasetComponentOption | LegendComponentOption | BarSeriesOption | PieSeriesOption | LineSeriesOption | ScatterSeriesOption
>

interface Props {
  option?: ECOption
  loading?: boolean
}

const props = defineProps<Props>()

use([CanvasRenderer, DatasetComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, LineChart, PieChart, ScatterChart])

let chart: EChartsType | null
const echartRef = ref<HTMLDivElement | null>(null)

const normalizedOption = computed(() => props.option ?? {})

const resize = () => {
  const rect = echartRef.value?.getBoundingClientRect()
  chart?.resize({ width: rect?.width, height: rect?.height })
}
const setOption = (option: ECOption) => chart?.setOption(option)

let watchEvt: WatchStopHandle | null

const cleanup = () => {
  chart?.dispose()
  chart = null
  watchEvt?.()
  watchEvt = null
  window.removeEventListener('resize', resize)
}

const init = () => {
  if (!echartRef.value) return
  const rect = echartRef.value.getBoundingClientRect()
  chart = EchartInit(echartRef.value, '', { width: rect.width, height: rect.height })
  watchEvt = watchEffect(() => setOption(normalizedOption.value), { flush: 'post' })
  window.addEventListener('resize', resize)
}

onMounted(init)
onBeforeMount(cleanup)
</script>
