<template>
  <div class="h-full bg-neutral-100 flex flex-col">
    <view-item-header></view-item-header>

    <div class="px-6 flex-1 overflow-auto">
      <div class="h-full flex flex-col">
        <div v-if="showHeadSlots" class="h-20 flex items-center justify-between gap-6">
          <div :class="['h-full flex items-center', store.collapse ? 'w-40' : 'w-120']">
            <span class="font-medium text-2xl">{{ title }}</span>
          </div>

          <div class="flex-1">
            <slot name="head"></slot>
          </div>

          <div class="view-item__operation flex gap-6">
            <slot name="operation"></slot>
          </div>
        </div>

        <div class="flex-1 overflow-auto">
          <div class="h-full flex gap-6">
            <div v-if="showMainfestSlots" :class="['h-full', store.collapse ? 'w-40' : 'w-120']">
              <slot name="manifest"></slot>
            </div>
            <div class="flex-1 overflow-auto">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue'
import { useStore } from '@enocloud/utils'
import ViewItemHeader from '@components/project/view-item-header.vue'

export interface Props {
  title?: string
}

const props = defineProps<Props>()
const slots = useSlots()

const store = useStore()

const showHeadSlots = computed(() => !!props.title)

const showMainfestSlots = computed(() => !!slots.manifest)
</script>

<style>
.view-item__operation .el-button + .el-button {
  margin-left: 0;
}
</style>
