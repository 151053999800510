<template>
  <el-checkbox v-model="_modelValue" :disabled="disabled" :label="label" :true-label="trueLabel" :false-label="falseLabel" :checked="checked">
    <slot></slot>
  </el-checkbox>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  checked?: boolean
  disabled?: boolean
  label?: string | number | boolean | object
  modelValue?: string | number | boolean
  trueLabel?: string | number
  falseLabel?: string | number
}

interface Emits {
  (e: 'update:model-value', value: Props['modelValue']): void
  (e: 'change', value: Props['modelValue']): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    emits('change', value)
  }
})
</script>
