<template>
  <el-select
    v-model="_modelValue"
    ref="selectRef"
    :clearable="clearable"
    :collapse-tags="collapseTags"
    :collapse-tags-tooltip="collapseTagsTooltip"
    :remote="remote"
    :loading="loading"
    :filterable="filterable"
    :remote-method="remoteMethod"
    :default-first-option="defaultFirstOption"
    :multiple="multiple"
    :placeholder="placeholder"
    :teleported="_teleported"
    :value-key="valueKey"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
  >
    <slot></slot>
  </el-select>
</template>

<script setup lang="ts">
import { computed, inject, unref, ref } from 'vue'
import type { ElSelect } from 'element-plus'
import { EnFormKey } from '@components/shared'

interface Props {
  clearable?: boolean
  collapseTags?: boolean
  collapseTagsTooltip?: boolean
  defaultFirstOption?: boolean
  filterable?: boolean
  loading?: boolean
  modelValue?: string | number | boolean | unknown[] | Record<string, any>
  multiple?: boolean
  placeholder?: string
  remote?: boolean
  remoteMethod?: Function
  teleported?: boolean
  valueKey?: string
}

interface Emits {
  (e: 'update:model-value', value: unknown): void
  (e: 'change', value: unknown): void
  (e: 'blur'): void
  (e: 'focus'): void
}

const props = withDefaults(defineProps<Props>(), { teleported: true })
const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    emits('change', value)
  }
})

const enFormContext = inject(EnFormKey, undefined)
const _teleported = computed(() => unref(enFormContext?.teleported) || props.teleported)

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null)

defineExpose({
  focus: () => selectRef.value?.focus()
})
</script>
