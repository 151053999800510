<template>
  <el-tag :color="color" :closable="closable" :effect="effect" :type="type" @close="onClose">
    <slot></slot>
  </el-tag>
</template>

<script setup lang="ts">
interface Props {
  color?: string
  closable?: boolean
  effect?: 'dark' | 'light' | 'plain'
  type?: 'success' | 'info' | 'warning' | 'danger' | ''
}

interface Emits {
  (e: 'close'): void
}

defineProps<Props>()

const emits = defineEmits<Emits>()

const onClose = () => emits('close')
</script>
