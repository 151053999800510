<template>
  <el-input
    v-model="_modelValue"
    ref="inputRef"
    :type="type"
    :placeholder="placeholder"
    :rows="rows"
    :maxlength="maxlength"
    :show-word-limit="showWordLimit"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
  >
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend"></slot>
    </template>
    <template v-if="$slots.append" #append>
      <slot name="append"></slot>
    </template>
  </el-input>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { ElInput } from 'element-plus'

interface Props {
  maxlength?: string | number
  modelValue?: any
  placeholder?: string
  showWordLimit?: boolean
  type?: string
  rows?: number
}

interface Emits {
  (e: 'update:model-value', value?: string | number | null): void
  (e: 'change', value?: string | number | null): void
  (e: 'blur', value: Event): void
  (e: 'focus', value: Event): void
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: '请输入'
})

const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    emits('change', value)
  }
})

const inputRef = ref<InstanceType<typeof ElInput> | null>(null)

defineExpose({
  focus: () => inputRef.value?.focus(),
  blur: () => inputRef.value?.blur()
})
</script>
