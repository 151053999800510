<template>
  <el-dialog
    v-model="_modelValue"
    :width="width"
    :show-close="false"
    :center="center"
    :align-center="alignCenter"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :destroy-on-close="destroyOnClose"
    @close="$emit('close')"
  >
    <div class="mb-4 flex items-center justify-between">
      <span>{{ title }}</span>
      <div class="flex items-center gap-6">
        <slot name="operation"></slot>
        <i-close class="w-4 h-4 cursor-pointer hover:text-primary" @click="onClose"></i-close>
      </div>
    </div>

    <slot></slot>

    <template #footer>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Close as IClose } from '@element-plus/icons-vue'

interface Props {
  alignCenter?: boolean
  center?: boolean
  destroyOnClose?: boolean
  modelValue?: boolean
  title?: string
  width?: number | string
}

interface Emits {
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}

const props = withDefaults(defineProps<Props>(), {})

const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emits('update:modelValue', value)
})

const onClose = () => {
  _modelValue.value = false
  emits('close')
}
</script>

<style>
.el-dialog {
  border-radius: 8px;
  --el-dialog-content-font-size: 16px;
}

.el-dialog__header {
  display: none;
}

.el-dialog__body {
  padding: 24px;
}
</style>
