<template>
  <el-button :disabled="disabled" :type="type" :link="link" :size="size" ref="buttonRef">
    <slot></slot>
  </el-button>

  <el-popover ref="popoverRef" virtual-triggering :virtual-ref="buttonRef" trigger="click" placement="top-start" width="200" title="提示">
    <div class="flex flex-col gap-8">
      <span>{{ content }}</span>
      <div class="flex justify-end">
        <en-button size="small" text @click="onCancelClick">取消</en-button>
        <button-ajax size="small" type="primary" :method="onConfirmClick" :params="params">确定</button-ajax>
      </div>
    </div>
  </el-popover>
</template>

<script setup lang="ts">
import { ref, unref } from 'vue'
import { ElPopover, type ComponentSize } from 'element-plus'
import { EnButton, ButtonAjax } from '@components/index'

interface Props {
  content?: string
  disabled?: boolean
  type?: '' | 'default' | 'primary' | 'success' | 'warning' | 'info' | 'danger' | 'text'
  link?: boolean
  method?: (...args: any[]) => any
  params?: any
  size?: ComponentSize
}

const props = withDefaults(defineProps<Props>(), { content: '确定删除吗？', type: 'primary', link: true })

const buttonRef = ref<InstanceType<typeof EnButton> | null>()
const popoverRef = ref()

const onConfirmClick = async (...params: unknown[]) => {
  try {
    await props.method?.(...params)
  } catch (e) {
    console.error(e)
  } finally {
    onCancelClick()
  }
}

const onCancelClick = () => {
  unref(popoverRef).hide?.()
}
</script>
