<template>
  <div class="bg-white shadow rounded-2">
    <div :class="['p-6 box-border', ...normalizedBodyClass]">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { isArray } from 'lodash-es'

interface Props {
  bodyClass?: string | Array<string>
}

const props = defineProps<Props>()

const normalizedClass = (str: string | Array<string> | undefined): Array<string> => (str ? (isArray(str) ? str : str.split(' ')) : [])

const normalizedBodyClass = computed(() => normalizedClass(props.bodyClass))
</script>
