<template>
  <el-form
    ref="formRef"
    :model="model"
    :rules="rules"
    :inline="inline"
    :label-position="labelPosition"
    :label-width="labelWidth"
    :label-suffix="labelSuffix"
    inline-message
    v-loading="loading"
  >
    <slot></slot>
  </el-form>
</template>

<script setup lang="ts">
import { provide, ref, toRef } from 'vue'
import type { FormInstance } from 'element-plus'
import { EnFormKey } from '@components/shared'

interface Props {
  inline?: boolean
  labelPosition?: 'left' | 'right' | 'top'
  labelWidth?: number | string
  labelSuffix?: string
  loading?: boolean
  model?: object
  rules?: object
  popover?: boolean
  teleported?: boolean
  inlineMessage?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  labelPosition: 'top'
})

const formRef = ref<FormInstance | null>()

provide(EnFormKey, { teleported: toRef(props, 'teleported') })

defineExpose({
  clearValidate: () => formRef.value?.clearValidate(),
  resetFields: () => formRef.value?.resetFields(),
  validate: () => formRef.value?.validate(),
  validateField: () => formRef.value?.validateField()
})
</script>
