<template>
  <en-button :type="type" :link="link" :loading="loading || _loading" :disabled="disabled" @click="onClick(params)">
    <slot></slot>
  </en-button>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { EnButton } from '@components/index'

interface Props {
  type?: '' | 'default' | 'primary' | 'success' | 'warning' | 'info' | 'danger' | 'text'
  link?: boolean
  method?: (...args: any[]) => any
  params?: any
  disabled?: boolean
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), { type: 'primary' })

const _loading = ref(false)

const onClick = async (params: unknown) => {
  if (!Array.isArray(params)) params = [params]
  _loading.value = true
  try {
    await props.method?.(...(params as unknown[]))
  } catch (e) {
    console.error(e)
  } finally {
    _loading.value = false
  }
}
</script>
