<template>
  <div ref="el" class="flex flex-col">
    <div v-if="flattenFilters.length" ref="filtersRef" class="flex items-center gap-4" style="margin-bottom: 20px">
      <en-tag v-for="item of flattenFilters" closable @close="onDeleteFilter(item)">
        <span>{{ item.column.columnName?.message }}</span>
        <span>：</span>
        <span>{{ normalizedFilterValue(item) }}</span>
      </en-tag>

      <en-button type="primary" size="small" @click="onResetFiltersClisk">清空</en-button>
    </div>

    <en-table
      ref="tableRef"
      :data="data"
      :height="_height"
      :loading="loading || dynamicLoading"
      :paging="paging"
      :pagination="pagination"
      :show-summary="showSummary"
      :summary="summary"
      :summaryMethod="summaryMethod"
      :showItemCount="showItemCount"
      :method="method"
      :columns="normalizedColumns"
      @header-dragend="onHeaderDragend"
      @selection-change="onSelectionChange"
      @row-click="onRowClick"
      :addition="addition"
    >
      <en-table-column v-if="showSelectable" type="selection" width="55"></en-table-column>

      <en-table-column
        v-for="column of normalizedColumns"
        :key="column.columnName?.code"
        :label="column.label"
        :prop="column.prop"
        :width="column.width"
        :align="column.align"
        :show-overflow-tooltip="column.showOverflowTooltip"
      >
        <template
          v-if="column.columnName?.description || column.header.filterable || column.header.sortable || column.header.clickable"
          #header="{ column: _column }"
        >
          <div :ref="(el) => setHeaderColumnRef(el, column)" class="flex flex-wrap justify-center items-center gap-1">
            <span>{{ _column.label }}</span>

            <en-icon
              v-if="column.columnName?.description"
              @mouseenter="onColumnHeaderMouseenter(column)"
              @mouseleave="onColumnHeaderMouseleave(column)"
            >
              <i-warning-filled></i-warning-filled>
            </en-icon>

            <en-icon v-if="column.header.filterable" @click="onColumnHeaderFilterClick(column)" class="cursor-pointer">
              <i-search></i-search>
            </en-icon>

            <div v-if="column.header.sortable" class="flex flex-col items-center">
              <en-icon
                class="cursor-pointer"
                :style="{
                  '--color': sortedBy.code === column?.columnName?.code && sortedBy.order === 'ascending' ? 'var(--el-color-primary)' : 'inherit'
                }"
                @click="onColumnHeaderSortableClick(column, 'ascending')"
              >
                <i-caret-top></i-caret-top>
              </en-icon>
              <en-icon
                class="cursor-pointer"
                :style="{
                  '--color': sortedBy.code === column?.columnName?.code && sortedBy.order === 'descending' ? 'var(--el-color-primary)' : 'inherit'
                }"
                @click="onColumnHeaderSortableClick(column, 'descending')"
              >
                <i-caret-bottom></i-caret-bottom>
              </en-icon>
            </div>

            <en-icon
              v-if="column.header.clickable"
              @click="onColumnHeaderClick(column)"
              class="cursor-pointer"
              style="color: var(--el-color-primary)"
            >
              <i-edit></i-edit>
            </en-icon>
          </div>
        </template>

        <template v-if="$slots[column.columnName?.code!]" #default="{ row, $index }">
          <slot :name="column.columnName!.code!" :row="row" :$index="$index"></slot>
        </template>

        <template v-else-if="column.columnName?.code === 'INDEX'" #default="{ $index }">
          <span>{{ $index + 1 }}</span>
        </template>

        <template v-else-if="column.columnName?.dataType === 'CURRENCY'" #default="{ row }">
          <span>{{ formatMoney(get(row, column.columnName?.prop!)) }}</span>
        </template>

        <template v-else-if="column.columnName?.dataType === 'DATE'" #default="{ row }">
          <span>{{ formatDate(get(row, column.columnName?.prop)) }}</span>
        </template>

        <template v-else-if="column.columnName?.dataType === 'DATETIME'" #default="{ row }">
          <span>{{ formatDate(get(row, column.columnName?.prop), true) }}</span>
        </template>

        <template v-else-if="column.columnName?.dataType === 'PERCENTAGE'" #default="{ row }">
          <span>{{ formatPercent(get(row, column.columnName?.prop)) }}</span>
        </template>
      </en-table-column>

      <en-table-column fixed="right" :resizable="false" width="50" class-name="en-table-setting__column" label-class-name="en-table-setting__label">
        <template #header>
          <en-button link ref="settingBtnRef" @click="onSettingBtnClick">
            <el-icon>
              <i-setting></i-setting>
            </el-icon>
          </en-button>
        </template>
      </en-table-column>
    </en-table>
  </div>

  <el-popover :visible="settingPopoverVisible" ref="settingPopoverRef" :virtual-ref="settingBtnRef" trigger="click" virtual-triggering width="380">
    <div v-loading="loading || dynamicLoading" class="h-85 grid gap-2" style="grid-template-columns: 1fr 2fr">
      <el-scrollbar view-class="select-no flex flex-col">
        <div
          v-for="item of configs"
          :class="['p-2 cursor-pointer flex items-center justify-between hover:bg-[#F5FBFF] gap-1', { 'text-primary': activeConfig?.id === item.id }]"
        >
          <div class="flex-1" @click="setActiveConfig(item)" @dblclick="updatePendingConfig(item)">
            <en-input
              v-if="pendingConfig.id === item.id"
              v-model="pendingConfig.name"
              autofocus
              @blur="savePendingConfig"
              @keyup.enter="savePendingConfig"
            ></en-input>
            <span v-else>{{ item.name }}</span>
          </div>

          <button-delete v-if="!item.readonly?.value && !pendingConfig.id" size="small" :method="deleteConfig" :params="item">
            <en-icon>
              <i-delete></i-delete>
            </en-icon>
          </button-delete>
        </div>

        <en-button v-if="!pendingConfig.type?.code && configs.length < 6" type="primary" text @click="addConfig">添加 +</en-button>
      </el-scrollbar>

      <div class="h-85 flex flex-col">
        <div class="py-3 border-b border-b-solid border-gray-200">{{ activeConfig?.name }}</div>
        <el-scrollbar class="flex-1 overflow-auto">
          <vue-draggable
            v-model="columns"
            :item-key="(column: TableConfigField) => column.columnName?.code"
            :component-data="{ tag: 'ul', type: 'transition-group' }"
            :animation="200"
            ghostClass="table-dynamic__ghost"
            @start="onDragStart"
            @end="onDragEnd"
            @change="onDragChange"
          >
            <template #item="{ element }: { element: TableConfigField; index: number }">
              <div class="cursor-move flex items-center justify-between p-2 hover:bg-neutral-200">
                <span>{{ element.columnName?.message }}</span>
                <el-switch
                  :model-value="element.visible?.value as boolean"
                  :disabled="!!pendingConfig.id"
                  @change="(value) => onSwitchChange(value, element)"
                ></el-switch>
              </div>
            </template>
          </vue-draggable>
        </el-scrollbar>
      </div>
    </div>
  </el-popover>

  <el-tooltip
    v-model:visible="tooltipVisible"
    ref="tooltipRef"
    placement="top"
    :content="headerColumnActive?.columnName?.description"
    virtual-triggering
    :virtual-ref="tooltipVirtualRef"
  ></el-tooltip>

  <el-popover
    ref="filterRef"
    :visible="filterVisible"
    virtual-triggering
    :virtual-ref="filterVirtualRef"
    :show-arrow="false"
    popper-style="--el-popover-padding: 0"
    @after-enter="onFilterAfterEnter"
    @after-leave="onFilterAfterLeave"
  >
    <div v-click-outside="onFilterOutsideClick" class="w-55">
      <template v-if="headerColumnConfig?.header?.filter?.type === 'select'">
        <select-maintain
          :model-value="filterValue"
          :collapse-tags="headerColumnConfig?.header?.filter.props?.multiple"
          :teleported="false"
          :key="headerColumnActive?.columnName?.code"
          v-bind="headerColumnConfig?.header?.filter.props"
          :props="{
            label: headerColumnConfig?.header?.filter.props?.props?.label ?? 'label',
            value:
              headerColumnConfig?.header?.filter.props?.props?.value === 'id' || headerColumnConfig?.header?.filter.props?.props?.value === 'code'
                ? ''
                : headerColumnConfig?.header?.filter.props?.props?.value ?? 'value'
          }"
          :value-key="headerColumnConfig?.header?.filter.props?.props?.valueKey || headerColumnConfig?.header?.filter.props?.props?.value"
          ref="filterElRef"
          @change="onFilterValueChange"
          class="w-full"
          lazy
        ></select-maintain>
      </template>
      <template v-else-if="headerColumnConfig?.header?.filter?.type === 'date'">
        <en-date-picker
          :model-value="filterValue"
          :start="filterValue?.[0]"
          :end="filterValue?.[1]"
          :teleported="false"
          :key="headerColumnActive?.columnName?.code"
          v-bind="headerColumnConfig?.header?.filter.props"
          ref="filterElRef"
          @change="onFilterValueChange"
          class="w-full"
        ></en-date-picker>
      </template>
      <template v-else-if="headerColumnConfig?.header?.filter?.type === 'cascader'">
        <cascader-maintain
          :model-value="filterValue"
          :key="headerColumnActive?.columnName?.code"
          :props="{
            label: headerColumnConfig?.header?.filter.props?.props?.label ?? 'label',
            value:
              headerColumnConfig?.header?.filter.props?.props?.value === 'id' || headerColumnConfig?.header?.filter.props?.props?.value === 'code'
                ? ''
                : headerColumnConfig?.header?.filter.props?.props?.value ?? 'value'
          }"
          :value-key="headerColumnConfig?.header?.filter.props?.props?.valueKey || headerColumnConfig?.header?.filter.props?.props?.value"
          ref="filterElRef"
          @change="onFilterValueChange"
          v-bind="headerColumnConfig?.header?.filter.props"
        ></cascader-maintain>
      </template>
      <template v-else>
        <en-input :model-value="filterValue" ref="filterElRef" @change="onFilterValueChange" @keyup.enter="onFilterEnter"></en-input>
      </template>
    </div>
  </el-popover>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { computed, ref, toRef, watch, onUnmounted, nextTick } from 'vue'
import {
  concat,
  find,
  get,
  isArray,
  isEqual,
  debounce,
  throttle,
  isObject,
  isEqualWith,
  isString,
  isFunction,
  isBoolean,
  isUndefined,
  cloneDeep,
  map,
  filter,
  isPlainObject,
  keys
} from 'lodash-es'
import VueDraggable from 'vuedraggable'
import { useElementSize } from '@vueuse/core'
import {
  Delete as IDelete,
  Search as ISearch,
  Setting as ISetting,
  WarningFilled as IWarningFilled,
  CaretTop as ICaretTop,
  CaretBottom as ICaretBottom,
  Edit as IEdit
} from '@element-plus/icons-vue'
import {
  ButtonDelete,
  EnIcon,
  EnTable,
  EnTableColumn,
  EnButton,
  EnInput,
  EnTag,
  SelectMaintain,
  EnDatePicker,
  CascaderMaintain
} from '@components/index'
import { ajax } from '@enocloud/utils'

import type { Ref } from 'vue'
import type { TableColumnCtx, TooltipInstance, ClickOutside as VClickOutside } from 'element-plus'
import type { TableConfig as FactoryTableConfig, _TableConfig } from '@enocloud/utils'

type TableConfig = EnocloudCommonDefinitions['TableConfigDto']
type TableConfigField = EnocloudCommonDefinitions['TableConfigFieldDto']
type TablePaging = EnocloudCommonDefinitions['PagingResultDto']

export type TableColumn = Pick<TableConfigField, 'columnName' | 'width'> &
  Pick<_TableConfig, 'align' | 'label' | 'prop' | 'showOverflowTooltip'> & {
    columnConfig?: _TableConfig
    header: {
      filterable: boolean
      sortable: boolean
      clickable: boolean
    }
  }

export type TableSortCtx = { column: TableColumn; order: 'descending' | 'ascending' | '' }

interface TableFilter {
  column: TableColumn
  config: _TableConfig
  value: any
}

interface Props {
  code: string
  data?: unknown[]
  height?: number
  loading?: boolean
  method?: Function
  paging?: TablePaging
  pagination?: boolean
  showSelectable?: boolean
  showItemCount?: boolean
  showSummary?: boolean
  summary?: unknown
  summaryMethod?: (data: any) => string[]
  config?: FactoryTableConfig
  sortable?: boolean
  selection?: any[]
}

interface Emits {
  (e: 'row-click', value: unknown): void
  (e: 'current-change', value: unknown): void
  (e: 'update:paging', value: TablePaging): void
  (e: 'selection-change', value: unknown[]): void
  (e: 'header-click', value: TableColumn): void
  (e: 'sort-click', value: TableSortCtx): void
  (e: 'update:selection', value: any[]): void
}

const props = withDefaults(defineProps<Props>(), {
  data: () => [],
  paging: () => {
    return {
      pageIndex: 1,
      pageSize: 20,
      pageCount: 0,
      itemCount: 0
    }
  }
})

const emits = defineEmits<Emits>()

const tableRef = ref<InstanceType<typeof EnTable> | null>()

const filters = ref<TableFilter[]>([])
const filtersRef = ref<HTMLDivElement | null>(null)
const flattenFilters = computed<TableFilter[]>(() =>
  filters.value.reduce((result, item) => {
    if (item.config.header?.filter?.type === 'cascader') {
      result.push({ column: item.column, config: item.config, value: item.value })
    } else if (item.config.header?.filter?.type !== 'date' && isArray(item.value)) {
      item.value.forEach((v) => {
        result.push({ column: item.column, config: item.config, value: v })
      })
    } else if (item.value !== null) {
      result.push(item)
    }

    return result
  }, [] as TableFilter[])
)

const sortedBy = ref<{ code: string; order: 'descending' | 'ascending' | '' }>({ code: '', order: '' })
const filterAddition = computed(() => {
  return filters.value.reduce(
    (result, item) => {
      switch (item.config.header?.filter?.type) {
        case 'cascader':
          if (isArray(item.value)) {
            if (isString(item.config.header?.filter?.field!)) {
              result[item.config.header?.filter?.field!] = item.value.reduce((res, v, i) => {
                const _props = isArray(item.config.header?.filter?.props?.props)
                  ? item.config.header?.filter?.props?.props[i]
                  : item.config.header?.filter?.props?.props
                const { value, valueKey } = _props
                if (isArray(v)) {
                  res = concat(
                    res,
                    v.map((i) => get(i, value === '' ? valueKey : value))
                  )
                } else {
                  res.push(get(v, value === '' ? valueKey : value))
                }
                return res
              }, [] as any[])
            }
          }
          break
        default:
          if (isArray(item.config.header?.filter?.field)) {
            item.config.header?.filter?.field.forEach((f, i) => {
              result[f] = item.value?.[i]
            })
          } else if (isArray(item.value)) {
            result[item.config.header?.filter?.field!] = item.value.map((v) =>
              isObject(v) ? get(v, item.config.header?.filter?.props?.props?.value) : v
            )
          } else if (isObject(item.value)) {
            result[item.config.header?.filter?.field!] = get(item.value, item.config.header?.filter?.props?.props?.value)
          } else {
            result[item.config.header?.filter?.field!] = item.value
          }
          break
      }

      return result
    },
    {} as Record<string, unknown>
  )
})

const addition = computed(() => {
  const _addition: Record<string, any> = Object.assign({}, filterAddition.value)
  if (sortedBy.value.code) {
    _addition.sortedBy = sortedBy.value.code + (sortedBy.value.order === 'descending' ? '_desc' : '')
  }
  return _addition
})

const normalizedFilterValue = (filter: TableFilter) => {
  let value = ''

  switch (filter.config.header?.filter?.type) {
    case 'cascader':
      value = (filter.value as any[]).reduce((res, v, i) => {
        const _props = isArray(filter.config.header?.filter?.props?.props)
          ? filter.config.header?.filter?.props?.props[i]
          : filter.config.header?.filter?.props?.props
        const { label } = _props
        if (isArray(v)) {
          res += (i ? '/' : '') + v.map((i) => get(i, label)).join(',')
        } else {
          res = get(v, label)
        }
        return res
      }, '')
      break
    case 'select':
      if (filter.config?.header?.filter.props?.props?.value === 'id' || filter.config?.header?.filter.props?.props?.value === 'code') {
        value = get(filter.value, filter.config?.header?.filter.props?.props?.label)
      } else {
        value = filter.value
      }
      break
    case 'date':
      value = isArray(filter.value) ? `${filter.value?.[0]}-${filter.value?.[1]}` : filter.value
      break
    default:
      value = filter.value
      break
  }

  return value
}

const onDeleteFilter = async (filter: TableFilter) => {
  const index = filters.value.findIndex((item) => item.column.columnName?.code === filter.column.columnName?.code)
  if (index > -1) {
    const current = filters.value[index]
    switch (current.config.header?.filter?.type) {
      case 'select':
        if (isArray(current.value)) {
          current.value.splice(
            current.value.findIndex((v) => isEqual(v, filter.value)),
            1
          )
        } else {
          filters.value.splice(index, 1)
        }
        break
      default:
        filters.value.splice(index, 1)
    }
  }
  await nextTick()
  props.method?.({ addition: addition.value, invokedByDynamicAddition: true })
}

const onResetFiltersClisk = () => {
  filters.value = []
  props.method?.({ addition: addition.value, invokedByDynamicAddition: true })
}

const onSettingBtnClick = () => {
  settingPopoverVisible.value = true
}

const useTableDynamic = (code: Ref<string>, columnConfig: Ref<FactoryTableConfig | undefined>) => {
  const configs = ref<TableConfig[]>([])

  const pendingConfig = ref<TableConfig>({ name: '', type: { code: '', message: '', description: '', type: '' } })

  const activeConfig = ref<TableConfig | null>(null)

  const addConfig = () => {
    if (pendingConfig.value.type?.code) return
    pendingConfig.value.name = `未命名${configs.value.length || ''}`
    pendingConfig.value.type = { code: code.value, message: '', description: '', type: '' }
    configs.value.push(pendingConfig.value)
  }

  const deleteConfig = async (config: TableConfig) => {
    if (!config.id) {
      configs.value.splice(configs.value.findIndex((item) => !item.id)!, 1)
      return
    }
    loading.value = true
    await ajax('DELETE /enocloud/common/table/config/:tableConfigId', { paths: [config.id] })
    loading.value = false
    getConfigs()
  }

  const savePendingConfig = async () => {
    if (!pendingConfig.value.name) return
    try {
      await ajax(pendingConfig.value.id ? 'PUT /enocloud/common/table/config' : 'POST /enocloud/common/table/config', {
        payload: pendingConfig.value
      })
      pendingConfig.value = { type: { code: '', message: '', description: '', type: '' } }
      getConfigs()
    } catch (err) {}
  }

  let timer: NodeJS.Timeout | null
  const setActiveConfig = (config: TableConfig) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      if (!config.id || activeConfig.value?.id === config.id) return
      activeConfig.value = config
      ajax('POST /enocloud/common/table/config/:tableConfigId/trigger', { paths: [config.id] })
    }, 300)
  }

  const updatePendingConfig = (config: TableConfig) => {
    if (timer) clearTimeout(timer)
    pendingConfig.value = config
  }

  const columns = ref<TableConfigField[]>([])

  const loading = ref(false)

  const onSwitchChange = (value: string | boolean | number, item: TableConfigField) => {
    if (item.visible) {
      item.visible = Object.assign(item.visible, { code: value ? 'Y' : 'N', value })
      updateColumns()
    }
  }

  const getConfigs = async () => {
    loading.value = true
    try {
      const res = await ajax('GET /enocloud/common/table/config', { payload: { type: code.value } })
      configs.value = res.data
      activeConfig.value = configs.value.reduce(
        (latest, config) => (dayjs(latest.datetime).isAfter(dayjs(config.datetime)) ? latest : config),
        configs.value?.[0]
      )
    } finally {
      loading.value = false
    }
  }

  const getColumns = async () => {
    if (!activeConfig.value?.id) return
    loading.value = true
    try {
      const res = await ajax('GET /enocloud/common/table/config/:tableConfigId', { paths: [activeConfig.value?.id] })
      columns.value = res.data.filter((column) => {
        const config = get(columnConfig.value, column.columnName?.code!)
        if (config) {
          if (isFunction(config?.visible)) return config!.visible()
          if (isBoolean(config?.visible)) return config!.visible
        }
        return column
      })
    } finally {
      loading.value = false
    }
  }

  const updateColumns = async ({ data }: { data?: TableConfigField[] } = {}) => {
    loading.value = true
    try {
      await ajax('PUT /enocloud/common/table/config/:tableConfigId', { paths: [activeConfig.value?.id], data: data ?? columns.value })
    } finally {
      loading.value = false
    }
  }

  watch(code, getConfigs, { immediate: true })

  watch(activeConfig, getColumns)

  onUnmounted(() => timer && clearTimeout(timer))

  return {
    activeConfig,
    pendingConfig,
    configs,
    columns,
    loading,
    onSwitchChange,
    getColumns,
    updateColumns,
    setActiveConfig,
    addConfig,
    deleteConfig,
    updatePendingConfig,
    savePendingConfig
  }
}

const {
  activeConfig,
  pendingConfig,
  configs,
  columns,
  loading: dynamicLoading,
  onSwitchChange,
  setActiveConfig,
  addConfig,
  deleteConfig,
  savePendingConfig,
  updatePendingConfig,
  updateColumns
} = useTableDynamic(toRef(props, 'code'), toRef(props, 'config'))

const visibleColumns = computed(() => filter(columns.value, ['visible.value', true]))

const el = ref()
const { width: bodyWidth } = useElementSize(el, { width: 230, height: 0 })
const columnsWidth = computed(() => visibleColumns.value.reduce((width, column) => (width += column.width), 0))
const _bodyWidth = computed(() => bodyWidth.value - 50 - (props.showSelectable ? 55 : 0))
const multiplier = computed(() => (_bodyWidth.value > columnsWidth.value ? (_bodyWidth.value - columnsWidth.value) / columnsWidth.value : 0))

const normalizedColumn = (column: TableConfigField): TableColumn => {
  const { columnName, width: columnWidth } = column
  const config = get(props.config, column.columnName?.code!)
  const columnConfig = config
  const label = config?.label || columnName?.message
  const prop = config?.prop || columnName?.prop
  const align = config?.align || 'center'
  const showOverflowTooltip = config?.showOverflowTooltip || true
  const width = columnWidth + columnWidth * multiplier.value
  const filterable = !isUndefined(config?.header?.filter)
  const sortable = Boolean(columnName?.sortable) || Boolean(config?.header?.sortable)
  const clickable = Boolean(config?.header?.clickable)
  const header = { filterable, sortable, clickable }
  return { align, columnName, columnConfig, header, label, showOverflowTooltip, prop, width }
}
const normalizedColumns = computed<TableColumn[]>(() => map(visibleColumns.value, normalizedColumn))

const dragging = ref(false)

const onDragStart = () => {
  dragging.value = true
}

const onDragEnd = () => {
  dragging.value = false
}

const onHeaderDragend = (newWidth: TableColumnCtx<unknown>['width'], oldWidth: TableColumnCtx<unknown>['width'], column: TableColumnCtx<unknown>) => {
  if (Number(newWidth) === Number(oldWidth)) return
  if (Number(newWidth) < 80) newWidth = 80
  const index = column.getColumnIndex()
  const data = cloneDeep(columns.value)
  const current = find(data, ['columnName.code', normalizedColumns.value[props.showSelectable ? index - 1 : index].columnName?.code])
  if (!current) return
  current.width = Number(newWidth)
  updateColumns({ data })
}

const onDragChange = debounce(updateColumns, 1000)

const _height = computed(() =>
  props.height ? (filtersRef.value ? props.height - filtersRef.value.getBoundingClientRect().height - 20 : props.height) : 300
)

const settingBtnRef = ref<InstanceType<typeof EnButton> | null>()
const settingPopoverRef = ref<any>()
const settingPopoverVisible = ref(false)

const isPopoverContentRef = (node: HTMLElement | null | undefined): boolean => {
  return node
    ? typeof node.className === 'string' && node.className.includes('el-popover')
      ? true
      : isPopoverContentRef(node?.parentElement)
    : false
}

const onDocumentClick = (e: Event) => {
  if (isPopoverContentRef(e.target as HTMLElement)) {
  } else {
    settingPopoverVisible.value = false
  }
}

watch(settingPopoverVisible, (value) => {
  setTimeout(() => {
    if (value) document.addEventListener('click', onDocumentClick)
    else document.removeEventListener('click', onDocumentClick)
  })
})

const onSelectionChange = (value: any[]) => {
  emits('update:selection', value)
  emits('selection-change', value)
}

const headerColumnRefs = ref<Record<string, HTMLDivElement | null>>({})
const headerColumnActive = ref<TableColumn | null>(null)

const setHeaderColumnRef = (el: any, column: TableColumn) => {
  if (column.columnName?.code) {
    headerColumnRefs.value[column.columnName.code] = el as HTMLDivElement | null
  }
}

const tooltipRef = ref<TooltipInstance | null>()
const tooltipVisible = ref(false)
const tooltipVirtualRef = ref()

const onColumnHeaderMouseenter = throttle((column: TableColumn) => {
  headerColumnActive.value = column
  tooltipVirtualRef.value = headerColumnActive.value?.columnName?.code ? headerColumnRefs.value[headerColumnActive.value.columnName.code] : null
  tooltipVisible.value = true
}, 50)

const onColumnHeaderMouseleave = throttle((column: TableColumn) => {
  tooltipVisible.value = false
  headerColumnActive.value = null
}, 50)

const filterVisible = ref(false)
const filterRef = ref<any>(null)
const filterElRef = ref<any>(null)
const filterValue = computed(() => filters.value.find((item) => item.column.columnName?.code === headerColumnActive.value?.columnName?.code)?.value)
const filterVirtualRef = computed(() =>
  headerColumnActive.value?.columnName?.code ? headerColumnRefs.value[headerColumnActive.value?.columnName?.code] : null
)

const headerColumnConfig = computed(() => get(props.config, headerColumnActive.value?.columnName?.code!))

const onFilterOutsideClick = () => {
  filterVisible.value = false
}

const onColumnHeaderFilterClick = (column: TableColumn) => {
  headerColumnActive.value = column
  filterVisible.value = true
}

const onColumnHeaderClick = (column: TableColumn) => {
  headerColumnActive.value = column
  emits('header-click', column)
}

const onFilterValueChange = (value: any) => {
  const exist = find(filters.value, ['column.columnName.code', headerColumnActive.value?.columnName?.code])
  if (exist) exist.value = value
  else filters.value.push({ column: headerColumnActive.value!, config: headerColumnConfig.value!, value })
  filterRef.value?.popperRef.popperInstanceRef.update()

  if (headerColumnActive.value?.columnConfig?.header?.filter?.type === 'date') {
    filterVisible.value = false
  }
}

const onFilterEnter = () => {
  filterVisible.value = false
}

let additionMapCache: any
const onFilterAfterLeave = () => {
  filters.value = filters.value.filter((item) => (isPlainObject(item.value) && keys(item.value)) || item.value.length)
  if (!keys(addition.value).length || isEqualWith(addition.value, additionMapCache)) return
  additionMapCache = addition.value
  props.method?.({ addition: addition.value, invokedByDynamicAddition: true })
}

const onFilterAfterEnter = async () => {
  await nextTick()
  filterElRef.value?.focus()
}

const onRowClick = (value: unknown) => emits('row-click', value)

const onColumnHeaderSortableClick = (column: TableColumn, order: 'descending' | 'ascending') => {
  if (!column.columnName?.code) return
  if (sortedBy.value.code === column.columnName?.code && sortedBy.value.order === order) {
    sortedBy.value.code = ''
    sortedBy.value.order = ''
  } else {
    sortedBy.value.code = column.columnName?.code
    sortedBy.value.order = order
  }
  if (props.sortable) {
    emits('sort-click', { column, order })
  } else {
    props.method?.({ addition: addition.value })
  }
}

defineExpose({
  tableRef,
  toggleRowSelection: (row: any, selected: boolean) => tableRef.value?.tableRef?.toggleRowSelection(row, selected),
  clearSelection: () => tableRef.value?.tableRef?.clearSelection()
})
</script>

<style>
.en-table-setting__column,
.en-table-setting__column::before {
  box-shadow: unset !important;
}

.el-table__body .en-table-setting__column {
  display: none;
}

.en-table-setting__label {
  box-shadow: var(--el-table-fixed-right-column);
}

.table-dynamic__ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
