<template>
  <en-button :link="link" :type="type" :disabled="disabled" :loading="loading || _loading" @click="onClick">
    <slot></slot>
  </en-button>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { ajax, useStore } from '@enocloud/utils'

import { EnButton } from '@components/index'

import { type AjaxActionsMap } from '@enocloud/utils'

interface Props {
  ajax: { action: keyof AjaxActionsMap; params: (...params: any[]) => void }
  type?: '' | 'default' | 'primary' | 'success' | 'warning' | 'info' | 'danger' | 'text'
  link?: boolean
  method?: (...args: any[]) => any
  disabled?: boolean
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), { type: 'primary', link: true })

const store = useStore()

const _loading = ref(false)
const onClick = async () => {
  _loading.value = true
  try {
    const params = { payload: {}, paths: [] }
    props.ajax.params?.(params)
    await ajax(props.ajax.action, params)
    store.openDownload()
  } catch (err) {
  } finally {
    _loading.value = false
  }
}
</script>
