<template>
  <div class="w-full">
    <quill-editor
      v-model:content="_modelValue"
      content-type="html"
      :toolbar="[{ size: ['small', false, 'large', 'huge'] }, { header: [1, 2, 3, 4, 5, 6, false] }, 'bold', 'italic', 'image']"
      :placeholder="placeholder"
      :modules="modules"
      theme="snow"
    ></quill-editor>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { QuillEditor, Quill } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

import { useStore } from '@enocloud/utils'

import type { RangeStatic } from 'quill'

interface Props {
  dir?: string
  modelValue?: string
  placeholder?: string
}

interface Emits {
  (e: 'update:model-value', value?: string): void
  (e: 'change', value?: string): void
}

const props = withDefaults(defineProps<Props>(), {
  dir: '/',
  placeholder: '请输入'
})

const emits = defineEmits<Emits>()

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value)
    emits('change', value)
    console.log(value)
  }
})

const store = useStore()

interface ImageUploaderOptions {
  upload: (file: File) => Promise<string>
}

class ImageUploader {
  quill?: Quill | null
  options: ImageUploaderOptions
  range: RangeStatic = { index: 0, length: 0 }
  fileHolder?: HTMLInputElement
  placeholderDelta?: any

  constructor(quill: Quill | null, options: ImageUploaderOptions) {
    this.quill = quill
    this.options = options
    const toolbar = this.quill?.getModule('toolbar')
    toolbar?.addHandler('image', this.selectLocalImage.bind(this))
  }

  selectLocalImage() {
    this.quill?.focus()
    this.range = this.quill?.getSelection()!
    this.fileHolder = document.createElement('input')
    if (!this.fileHolder) return
    this.fileHolder.setAttribute('type', 'file')
    this.fileHolder.setAttribute('accept', 'image/*')
    this.fileHolder.setAttribute('style', 'visibility:hidden')
    this.fileHolder.onchange = this.fileChanged.bind(this)
    document.body.appendChild(this.fileHolder)
    this.fileHolder.click()
    window.requestAnimationFrame(() => document.body.removeChild(this.fileHolder!))
  }

  async fileChanged() {
    const file = this.fileHolder?.files?.[0]
    if (!file) return
    try {
      const imageUrl = await this.options.upload(file)
      this.inseartToEditor(imageUrl)
    } catch (err) {
      console.error(err)
    }
  }

  inseartToEditor(url: string) {
    const range = this.range
    this.quill?.insertEmbed(range.index, 'image', `${url}`, 'user')
    range.index++
    this.quill?.setSelection(range, 'user')
  }
}

const ImageUploaderModule = {
  name: 'imageUploader',
  module: ImageUploader,
  options: {
    upload: (file: File) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        const key =
          store.ossInfo?.dir + (props.dir?.startsWith('/') ? props.dir : `/${props.dir}`) + (props.dir?.endsWith('/') ? '' : '/') + file.name

        const ossInfo = Object.assign({ OSSAccessKeyId: store.ossInfo?.accessId, Signature: store.ossInfo?.signature, key }, store.ossInfo)

        const formData = new FormData()
        for (const [key, value] of Object.entries(ossInfo ?? {})) {
          if (value) formData.append(key, `${value}`)
        }
        formData.append('file', file, file.name)

        xhr.onload = () => {
          if (xhr.status < 200 || xhr.status >= 300) reject(xhr.response)
          else resolve(`${ossInfo.host}/${key}`)
        }
        xhr.open('POST', ossInfo.host!, true)
        xhr.send(formData)
      })
    }
  }
}

const modules = [ImageUploaderModule]
</script>
